import { Injectable } from '@angular/core';
import { WebService } from './web.service';
import { PaymentCXOneService } from './payment-cxone.service';
import { environment } from '../../../environments/environment';
import { LocalCacheService } from '../localCache.service';

@Injectable({
  providedIn: 'root',
})
export class CXOneService {
  public siteName: string;
  public loggedInUserName: string = "";
  public loggedInUserEmail: string = "";
  public maskCallUrl: string;
  public unMaskCallUrl: string;
  public maskingSession: any;
  public enableMasking: boolean = false;
  public maskCallTriggered ?: boolean ;

  constructor(private webService: WebService, 
    private paymentGatewayService: PaymentCXOneService,
    private localCacheService: LocalCacheService,
  ) {
    this.siteName = 'RAC';
    this.loggedInUserEmail = this.localCacheService.getSessionStorage('agentEmail') || '';

    this.maskCallUrl = environment.baseAPIUrl + 'quote/cxone/mask'; 
    this.unMaskCallUrl = environment.baseAPIUrl + 'quote/cxone/unmask'; 
    if (localStorage.getItem('mask')) {
      let atB = atob(localStorage.getItem('mask') || '');
      this.maskingSession = atB != null && atB != 'undefined' ? JSON.parse(atB) : null;
      if(this.maskingSession == null){
        localStorage.removeItem('mask');
      } else {
        this.unMaskCall();
      }
    }
   // if (this.loggedInUserEmail !== undefined && this.loggedInUserEmail !== null ) {
    //   console.log('CXOne Service agentEmail', this.loggedInUserEmail);
    //   setTimeout(() => {
    //     this.checkWindowUrl();
    //   }, 500);
    // }
    this.paymentGatewayService.cxOneCallStatus$.subscribe((cxOneModel: CxOneModel) => {
      this.loggedInUserEmail = this.localCacheService.getSessionStorage('agentEmail') || '';
      this.enableMasking = cxOneModel.isCallEnabled;
      this.maskCallTriggered = cxOneModel.maskCallTriggered;
      if ( this.siteName === 'RAC' && this.loggedInUserEmail && this.enableMasking) {
        this.checkWindowUrl();
      }
    });
  }

  checkWindowUrl() {
    if (this.enableMasking) {
      if (!this.maskingSession && this.maskCallTriggered) {
        this.maskCall();
      }
      else if (this.maskingSession && !this.maskCallTriggered) {
        this.unMaskCall();
      }
    }
  }

  maskCall() {
    const requestObj = {
      user: this.loggedInUserEmail,
      sitename: this.siteName,
    };
    this.webService.cxOnePostRequest(this.maskCallUrl, requestObj).subscribe((res: any) => {
      this.enableMasking = false;
      this.maskCallTriggered = undefined;  
      
      if (res.meta.statusCode == 202) {
        this.maskingSession = res?.data[0];
        localStorage.setItem('mask', btoa(JSON.stringify(this.maskingSession)),);           
      }
      else if (res.meta.statusCode != 424) {
        this.checkWindowUrl();
      }
    });
  }

  unMaskCall() {
    const requestobj = {
      sessionid: this.maskingSession?.sessionid,
      contactid: this.maskingSession?.contactid,
      sitename: this.siteName,
    };
    this.maskingSession = null;
    localStorage.removeItem('mask');
    this.webService.cxOnePostRequest(this.unMaskCallUrl, requestobj).subscribe((res:any) => { 
      if (res.meta.statusCode == 202) { 

      } 
      this.checkWindowUrl(); 
    });
  }

  validateUnmask(){
    if (localStorage.getItem('mask')) {
      let atB = atob(localStorage.getItem('mask') || '');
      this.maskingSession = atB != null && atB != 'undefined' ? JSON.parse(atB) : null;
      if(this.maskingSession == null){
        localStorage.removeItem('mask');
      } else {
        this.unMaskCall();
      }
    }
  }
}

export interface CxOneModel {
  isCallEnabled: boolean,
  maskCallTriggered? : boolean
}
