import { Component } from '@angular/core';
import { RacvFooterComponent } from '../../../components/racv-footer/racv-footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { RacvCommonHeaderComponent } from '../../../components/racv-common-header/racv-common-header.component';
import { Router, RouterLink } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { PDSService } from '../../../services/pds.service';
import { LocalCacheService } from '../../../services/localCache.service';
@Component({
  selector: 'app-cruise',
  standalone: true,
  imports: [
    RouterLink,
    RacvFooterComponent,
    StaticSearchComponent,
    RacvCommonHeaderComponent,
  ],
  templateUrl: './cruise.component.html',
  styleUrl: './cruise.component.scss',
})
export class CruiseComponent {
  customWordings: any = {};
  dynamicClassName: string = '';
  pdsLink: string = '';
  tmdLink: string = '';

  constructor(
    private titleService: Title,
    private router: Router, 
    private pdsService: PDSService,
    private localCacheService: LocalCacheService,
  ) {
    this.titleService.setTitle('Cruise');
  }

  ngOnInit(): void {
    let path = this.router.url.split('/')?.filter(Boolean)?.join('-');
    if (!path) {
      path = 'homepage';
    }
    this.dynamicClassName = path;
    const currentLatestPDS = this.localCacheService.getSessionStorage('lastestPDS');
    if (!currentLatestPDS) {
      this.pdsService.getLatestPDS().subscribe((data) => {
        if (data && data.pds && data.pds.length > 0) {
          this.pdsLink = data.pds[0].previewUrl;
        }
      });
    } else {
      this.pdsLink = currentLatestPDS?.previewUrl;
    }

    const currentLatestTMD = this.localCacheService.getSessionStorage('lastestTMD');
    if(!currentLatestTMD) {
      this.pdsService.getLatestTMD().subscribe((data) => {
        if (data && data.tmd && data.tmd.length > 0) {
          data.tmd.forEach((obj: any) => {
            if(obj.isActive) {
              this.tmdLink = obj.url;
            }
          });
        }
      });
    } else {
      this.tmdLink = currentLatestTMD?.url;
    }
  }

}
