<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header></app-racv-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/rac/page-header/travel-alerts-sub-page.png"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>COVID-19 FAQs</h1>
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="page-content-info">
            <h3>
              COVID-19 FAQs – For all policies purchased on or after 10 August
              2022
            </h3>
            <p>
              Please note that these FAQs should be read in conjunction with the
              Product Disclosure Statement (PDS) which contain the full terms
              and conditions, limits and exclusions of this policy. You should
              consider the PDS before making a decision about whether to
              purchase a policy.
            </p>
            <p>
              As a standard exclusion under all our travel policies, the
              country/region You are travelling to must not be subject to a Do
              Not Travel advice on the
              <span style="text-decoration: underline">
                <a href="https://www.smartraveller.gov.au/">Smartraveller</a>
              </span>
              website, either at the time You purchased Your policy or after the
              purchase of Your policy prior to Your departure. This is
              regardless of any exemption You may have received to travel and
              Your ability to purchase a policy. It is Your responsibility to
              check the Smartraveller website prior to Your departure to check
              if the country You are travelling to is subject to a Do Not Travel
              advice.
            </p>

            <p>
              <span
                >Any diagnosis of COVID-19 whether this be in Australia or
                overseas, must be made by a medical professional or government
                testing program. This would include diagnosis by Your local
                health authority or the relevant overseas health authority for
                the country You are travelling in.</span
              >
            </p>
            <p>
              <span
                >We have outlined the most commons scenarios that could be
                raised by our customers below. Please contact us if You have any
                questions or require any further information or clarification on
                cover. Customers always have a right to lodge a claim for
                consideration under their policy, which will be considered in
                line with policy terms and conditions and their individual
                circumstances</span
              >.
            </p>
            <p>
              <span
                >All capitalised terms have corresponding meaning as set out in
                the policy terms and conditions, unless otherwise
                indicated.</span
              >
            </p>
          </div>

          <div class="page-content-info pe-md-5 pe-0">
            <!-- Accordion -->
            <div class="card rounded-40 mb-3 shadow border-0">
              <div class="faqs-accorion accordion" id="faqsAccordion">
                <div class="accordion-item bg-transparent">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title rounded-top-10"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_01"
                      aria-expanded="false"
                      aria-controls="faqsContent_01"
                    >
                      Am I covered for COVID-19 benefits if a Do Not Travel
                      advice is issued after I have purchased my policy before
                      departing for my Trip?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_01"
                    class="accordion-collapse collapse show"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          Provided that You purchased the policy prior to any Do
                          Not Travel advice, You will be covered for Section 22
                          COVID-19 Cancellation Fees and Lost Deposits, subject
                          to full policy terms and conditions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_02"
                      aria-expanded="false"
                      aria-controls="faqsContent_02"
                    >
                      Am I covered for COVID-19 benefits if a Do Not Travel
                      advice is issued after I have departed for my Trip?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_02"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          If at the point of departure for Your Trip there is an
                          active Do Not Travel advisory in place for Your
                          country/region of travel, no cover will be available
                          to You for any claim associated for that
                          country/region where and so long as the Do Not Travel
                          advice applies. However, if a country/region becomes a
                          Do not Travel advice whilst on Your Trip, then if You
                          have purchased a policy with COVID-19 Benefits, You
                          are covered up to the policy terms and conditions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_03"
                      aria-expanded="false"
                      aria-controls="faqsContent_03"
                    >
                      Am I covered if I need to quarantine once I enter a
                      region/country based on the government or state
                      regulations?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_03"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          All policies have a General Exclusion relating to
                          claims directly or indirectly arising from any
                          government or public health authority mandatory
                          quarantine or isolation order imposed on You related
                          to border, region or territory travel in response to
                          COVID-19. Please check with local government and
                          public health authorities prior to Your departure.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_04"
                      aria-expanded="false"
                      aria-controls="faqsContent_04"
                    >
                      Do I need to declare COVID-19 as a medical condition if I
                      have previously been diagnosed with COVID-19?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_04"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          COVID-19 is not a medical condition that can be
                          declared under our policy. If You have chosen a plan
                          with the COVID-19 benefits, then You are covered if
                          You have previously suffered from COVID-19. If You
                          have not chosen a plan with COVID-19 benefits, there
                          is no cover for claim related to COVID-19.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_05"
                      aria-expanded="false"
                      aria-controls="faqsContent_05"
                    >
                      Am I covered for medical expenses if I am diagnosed with
                      COVID-19 during my trip?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_05"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-3">
                        <p>
                          Domestic - No medical expenses are covered in
                          Australia as we are prevented from paying by reason of
                          statutory legislation/government regulation.
                        </p>
                        <p>
                          International - Yes, Your reasonable overseas medical
                          and hospital expenses are covered, if You have
                          selected a plan with COVID-19 Benefits, unlimited and
                          subject to full policy terms and conditions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_06"
                      aria-expanded="false"
                      aria-controls="faqsContent_06"
                    >
                      Am I covered for funeral and emergency expenses if I die
                      from COVID-19 during my trip?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_06"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          Domestic – No medical expenses are covered in
                          Australia as we are prevented from paying by reason of
                          statutory legislation/government regulation.
                        </p>
                        <p>
                          International – Yes, If You, Your Children or
                          Grandchildren die while overseas, we will pay the
                          reasonable cost incurred, up to $20,000, for either
                          the funeral, cremation or the return of Your remains,
                          if You have selected the plan with COVID-19 benefits,
                          subject to full policy terms and conditions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_07"
                      aria-expanded="false"
                      aria-controls="faqsContent_07"
                    >
                      What if I am diagnosed with COVID-19 on my trip and I need
                      to quarantine are my emergency/additional expenses
                      covered?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_07"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        Domestic – Yes only on the plan with COVID-19 Benefits
                        under Section 23 COVID-19 Additional Expenses, subject
                        to full policy terms and conditions and up to a combined
                        limit of $3,500 per Adult.
                      </p>
                      <p>
                        International – Yes only on the plan with COVID-19
                        Benefits under Section 21F COVID-19 Benefits, where we
                        have accepted cover under Section 21A , subject to full
                        policy terms and conditions.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_08"
                      aria-expanded="false"
                      aria-controls="faqsContent_08"
                    >
                      Am I covered if I am deemed a close contact due to a
                      COVID-19 case and I need to quarantine before continuing
                      on with my trip?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_08"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          Yes, You are covered up to the combined policy limit
                          of $3,500 per Adult, on the plan with COVID-19
                          Benefits, subject to full policy terms and conditions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_09"
                      aria-expanded="false"
                      aria-controls="faqsContent_09"
                    >
                      What other emergency expenses do You cover?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_09"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        Domestic – No cover for emergency expenses under Section
                        21, however please refer to Section 23 COVID-19
                        Additional Expenses where You are covered up to the
                        combined policy limit of $3,500 per Adult on the plan
                        with COVID-19 Benefits, subject to full policy terms and
                        conditions.
                      </p>
                      <p>
                        International – On the plan with COVID-19 Benefits we
                        cover Your reasonable overseas medical and hospital
                        expenses and emergency expenses of any insured event
                        that we have accepted cover for under Section 21A,
                        subject to full policy terms and conditions.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_10"
                      aria-expanded="false"
                      aria-controls="faqsContent_10"
                    >
                      Do additional expenses and/or cancellation fees apply if
                      my relative or business partner dies from COVID-19 and I
                      need to cancel my trip or cut my trip short and come home?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_10"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          Yes, only on the plan with COVID-19 Benefits, subject
                          to full policy terms and conditions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_11"
                      aria-expanded="false"
                      aria-controls="faqsContent_11"
                    >
                      Am I covered for COVID-19 if I am travelling on a
                      multi-night cruise?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_11"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          Domestic - No and not available as an optional extra.
                        </p>
                        <p>
                          For Australian cruises, if You wish to access the
                          COVID-19 Benefits, You will need to select Australian
                          Cruises as a Destination and select Yes to 'Going on a
                          cruise?' this will fall under International Policy
                          with COVID-19 Benefits. Please note that this is an
                          optional extra that will be subject to an additional
                          fee.
                        </p>
                        <p>
                          International – Yes, only if You select Yes to ‘Going
                          on a cruise’ and select the plan that indicates
                          COVID-19 Benefits. Please note that this is an
                          optional extra that will be subject to an additional
                          fee.
                        </p>
                        <p>
                          We cover all other insured events as outlined in the
                          PDS so please ensure You select Yes to ‘Going on a
                          cruise? when choosing Your plan.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_12"
                      aria-expanded="false"
                      aria-controls="faqsContent_12"
                    >
                      Am I covered for loss of enjoyment of my cruise or
                      holiday, if I am required to isolate or quarantine due to
                      COVID-19 whilst on my holiday?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_12"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          No, all policies have a General Exclusion for any
                          Consequential loss which includes loss of enjoyment
                          and as such there is no cover for loss of enjoyment.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_13"
                      aria-expanded="false"
                      aria-controls="faqsContent_13"
                    >
                      Am I covered for my medical or evacuation costs for
                      Australian Cruising?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_13"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        If You select ‘Australian Cruise’ as Your country of
                        destination, You will be offered International policy
                        cover, which provides medical and evacuation benefits
                        whilst at sea subject to policy terms and conditions.
                        Please note that this is an optional extra that will be
                        subject to an additional fee.
                      </p>
                      <p>
                        However, no cover is available for medical and
                        evacuation expenses whilst in an Australian port, as we
                        do not cover medical expenses incurred in Australia (as
                        we are prevented from paying by reason of statutory
                        legislation/government regulation).
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_14"
                      aria-expanded="false"
                      aria-controls="faqsContent_14"
                    >
                      Am I covered for Cancellation Fees and Lost Deposits if I
                      am diagnosed with COVID-19 and I need to cancel my trip?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_14"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        Yes, You are covered up to the combined policy limit of
                        $3,500 per Adult, only on the plan with COVID-19
                        Benefits, subject to full policy terms and conditions.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_15"
                      aria-expanded="false"
                      aria-controls="faqsContent_15"
                    >
                      Am I covered if my travelling companion is diagnosed with
                      COVID-19 and I need to cut my trip short and/or come home?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_15"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          Yes, You are covered up to the combined policy limit
                          of $3,500 per Adult only on the plan with COVID-19
                          Benefits subject to full policy terms and conditions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_16"
                      aria-expanded="false"
                      aria-controls="faqsContent_16"
                    >
                      Am I covered if my travelling companion is diagnosed with
                      COVID-19 and my trip is cancelled?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_16"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          Yes, You are covered up to the combined policy limit
                          of $3,500 per Adult, only on the plan with COVID-19
                          Benefits, subject to full policy terms and conditions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_17"
                      aria-expanded="false"
                      aria-controls="faqsContent_17"
                    >
                      Am I covered if I am not vaccinated and it is a condition
                      of the transport provider that I must be vaccinated before
                      boarding?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_17"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        No, our policies do not provide cover if You do not
                        comply with the ticket conditions of the transport
                        provider as our policies have a General Exclusion for
                        failure to obtain the relevant travel documents.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_18"
                      aria-expanded="false"
                      aria-controls="faqsContent_18"
                    >
                      Am I covered if I am not vaccinated and it is a condition
                      of the country that I must be vaccinated before I enter?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_18"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          No, our policies do not provide cover if You do not
                          comply with the government regulations of the country
                          You are travelling to as our policies have a General
                          Exclusion for breaking any Government prohibition,
                          laws or regulations.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- accordion-item  19 -->
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_19"
                      aria-expanded="false"
                      aria-controls="faqsContent_19"
                    >
                      Am I covered for Cancellation Fees if my pre-paid
                      accommodation I planned to stay at is closed for cleaning
                      due to an outbreak of COVID-19?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_19"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          Yes, You are covered up to the combined policy limit
                          of $3,500 per Adult, only on the plan with COVID-19
                          Benefits, subject to full policy terms and conditions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 20 -->
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_20"
                      aria-expanded="false"
                      aria-controls="faqsContent_20"
                    >
                      Am I covered for Cancellation Fees or Lost Deposits if I
                      am a permanent employee of the healthcare industry and my
                      employer cancels my leave due to COVID-19?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_20"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          Yes, we would deem this as Essential Worker leave. You
                          are covered up to the combined policy limit of $3,500
                          per Adult, only on the plan with COVID-19 Benefits,
                          subject to full policy terms and conditions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!--  21-->
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_21"
                      aria-expanded="false"
                      aria-controls="faqsContent_21"
                    >
                      Do additional expenses apply if my pre-paid accommodation
                      I planned to stay at is closed for cleaning due to an
                      outbreak of COVID-19?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_21"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          Yes, You are covered up to the combined policy limit
                          of $3,500 per Adult, only on the plan with COVID-19
                          Benefits, subject to full policy terms and conditions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!--22  -->
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_22"
                      aria-expanded="false"
                      aria-controls="faqsContent_22"
                    >
                      Do additional expenses apply if I am denied boarding my
                      scheduled pre-paid public transport due to being suspected
                      of being infected with COVID-19?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_22"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          Yes, You are covered up to the combined policy limit
                          of $3,500 per Adult, only on the plan with COVID-19
                          Benefits subject to full policy terms and conditions.
                        </p>
                        <p>
                          However, as a condition of cover, we would require You
                          to provide proof, from the transport provider or
                          relevant authority, of the suspected COVID-19
                          infection.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 23 -->
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_23"
                      aria-expanded="false"
                      aria-controls="faqsContent_23"
                    >
                      Do I have cover under Special Events if my pre-arranged
                      special event is delayed because of COVID-19, which is
                      outside of my control, and I need to make alternative
                      arrangements to get to my destination on time?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_23"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          Yes, You are covered up to the combined policy limit
                          of $3,500 per Adult, only on the plan with COVID-19
                          Benefits under Section 23C, subject to full policy
                          terms and conditions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 24 -->
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_24"
                      aria-expanded="false"
                      aria-controls="faqsContent_24"
                    >
                      Do I have cover under Special Events if my pre-arranged
                      special event is cancelled because of COVID-19, and I wish
                      to cancel my trip?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_24"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          Yes, You are covered up to the combined policy limit
                          of $3,500 per Adult, only on the plan with COVID-19
                          Benefits, subject to full policy terms and conditions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 25 -->
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_25"
                      aria-expanded="false"
                      aria-controls="faqsContent_25"
                    >
                      Do I have cover under Travel Delay if my schedule
                      transport is delayed for more than 6 hours due to COVID-19
                      related delays and I need to pay additional expenses?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_25"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          Yes, You are covered up to the combined policy limit
                          of $3,500 per Adult, only on the plan with COVID-19
                          Benefits, subject to full policy terms and conditions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_26"
                      aria-expanded="false"
                      aria-controls="faqsContent_26"
                    >
                      Are there Additional benefits available if my trip
                      destination is Australia or New Zealand only?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_26"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          Domestic – Yes, in relation to Australia only- only on
                          the plan with COVID-19 Benefits, subject to policy
                          terms and conditions.
                        </p>
                        <p>
                          International – Yes, only on the plan with COVID-19
                          Benefits, subject to policy terms and conditions.
                        </p>
                        <p>
                          You will be covered, up to the combined policy limit
                          of $3,500 per Adult, under the following sections of
                          the policy:
                        </p>
                        <p>
                          Cancellation Fees and Lost Deposit – Section 22D -If
                          the person You’re due to stay with in Australia or New
                          Zealand is diagnosed with COVID-19, or directed by a
                          local public health authority into a period of
                          quarantine and You are unable to stay with them.
                        </p>
                        <p>
                          Additional Expenses – Section 23A - You can no longer
                          stay with the person You planned to stay with in
                          Australia or New Zealand because they are diagnosed
                          with COVID-19 and directed to enter a period of
                          quarantine; or Your pre-paid accommodation in
                          Australia or New Zealand is shutdown or closes as a
                          result of COVID-19.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-racv-footer></app-racv-footer>
</div>
