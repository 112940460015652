<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header></app-racv-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/rac/page-header/travel-alerts-sub-page.png"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Israel and the Occupied Palestinian Territories</h1>
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="page-content-info">
            <h3 class="text-center">Important Update – Israel Effective 1.30pm, 1st November 2024</h3>
            <p>
              DFAT has changed its travel advisory from ‘Do Not Travel’ to 
              ‘Reconsider your need to travel’ for Israel and the Occupied 
              Palestinian Territories, effective 1.30pm on 1st November 2024.
            </p>
            <p>
              Please refer to <a href="https://www.smartraveller.gov.au/" target="_blank">
              www.smartraveller.gov.au</a> for further information.
            </p>
            <p>
              Please refer to our Travel Alert Update on 9th October 2024 below 
              and note that our embargo on all new travel insurance coverage to 
              Israel remains in force until further notice. All other information 
              in that earlier update remains relevant to the situation in Israel, 
              including for customers due to travel or who are already in Israel.
            </p>
            <p>
              As a result, no new travel insurance cover for travel to Israel 
              can be purchased and Israel cannot be added as a destination to 
              existing policies until further notice.
            </p>
            <p>
              Limits, conditions and exclusions apply under your travel insurance 
              policy. Please refer to the Product Disclosure Statement and your 
              Certificate of Insurance for full details.
            </p>
          </div>

          <div class="page-content-info">
            <h3 class="text-center">Important Update – Israel Effective 7.30pm, 14th October 2024</h3>
            <p>
              DFAT has upgraded its travel advisory to ‘Do Not Travel’ for Israel 
              and the Occupied Palestinian Territories, due to the volatile 
              security situation, armed conflict, civil unrest and terrorism risk, 
              effective 7.30pm on 14th October 2024.
            </p>
            <p>
              Please refer to <a href="https://www.smartraveller.gov.au/" target="_blank">
              www.smartraveller.gov.au</a> for further information.
            </p>
            <p><strong>Travel policy coverage to Israel</strong></p>
            <p>
              Travel to a destination where there is an Australian Government 
              ‘Do Not Travel’ advisory in place is generally not covered by our 
              travel insurance policies. Refer to your policy documentation to 
              review the specific terms and exclusions they contain.
            </p>
            <p><strong>Customers due to travel to Israel</strong></p>
            <p>
              Travel to a destination where there is an Australian Government 
              ‘Do Not Travel’ advisory in place is generally not covered by our 
              travel insurance policies. Refer to your policy documentation to 
              review the specific terms and exclusions they contain.
            </p>
            <p>
              If your trip is affected and you wish to make a claim for our 
              consideration, you can do so.
            </p>
            <p><strong>Customers already in Israel</strong></p>
            <p>
              We advise you to contact our 24-hour Emergency Assistance Team for 
              any queries or assistance in departing Israel as soon as possible, 
              given the change in the travel advisory to Do Not Travel. Refer to 
              your policy document for contact details.
            </p>
            <p>
              If you are an Australian citizen or permanent resident in Israel 
              or the Occupied Palestinian Territories, the Department of Foreign 
              Affairs and Trade (DFAT) encourages travellers to register with its 
              crisis registration portal. See the 'Safety' section if their website, 
              for details on how to register on <a href="https://www.smartraveller.gov.au/" 
              target="_blank">smartraveller.gov.au</a>
            </p>
            <p>
              Australians needing emergency consular assistance should contact the 
              Australian Government's 24-hour Consular Emergency Centre on 
              <a href="tel:1300 555 135">1300 555 135</a> (within Australia) or 
              <a href="tel:+61 2 6261 3305">+61 2 6261 3305</a> (from overseas).
            </p>
            <p>
              Limits, conditions and exclusions apply under your travel insurance 
              policy. Please refer to the Product Disclosure Statement and your 
              Certificate of Insurance for full details.
            </p>
          </div>
          
          <div class="page-content-info">
            <h3 class="text-center">
              Important Update - Israel Effective 9th October 2024
            </h3>
            <p>
              Effective from 09 October 2024, we have applied an embargo on 
              the sales of all new travel insurance coverage to Israel, due 
              to the deteriorating security and escalation of the conflict 
              in the region.
            </p>
            <p>
              Whilst the Australian Government’s travel advice is to reconsider 
              your need to travel to Israel, we have assessed this against our 
              insurance risk appetite and whether our travel insurance would 
              provide adequate coverage under the circumstances, noting the 
              specific terms and exclusions relevant to the current conflict. 
              We have made the decision to cease offering cover.
            </p>
            <p><strong>As a result, no new travel insurance cover for travel to 
              Israel can be purchased and Israel cannot be added as a destination 
              to existing policies until further notice from 09 October 2024.
            </strong></p>
            <p><strong>Travel policy coverage to Israel</strong></p>
            <p>
              Acts of war as well as a traveller’s choice to travel to a 
              destination where there is an Australian Government ‘Do Not Travel’ 
              advisory in place are generally not covered by our travel insurance 
              policies. Refer to your policy documentation to review the specific 
              terms and exclusions they contain.
            </p>
            <p><strong>Customers due to travel to Israel</strong></p>
            <p>
              If you have a travel insurance policy and are traveling to Israel in 
              the near future, you will continue to be covered to the extent of the 
              terms of your policy, unless the Australian Government changes its 
              Smart Traveller advice for Israel to ‘Do Not Travel’, in which case 
              further policy coverage limitations and exclusions would apply.
            </p>
            <p>
              If your trip is affected and you wish to make a claim for our 
              consideration, you can do so.
            </p>
            <p><strong>Customers already in Israel</strong></p>
            <p>
              If you are already in Israel you will continue to be covered to the 
              extent of the terms of your policy but in the event of a change in 
              travel advice to ‘Do Not Travel’ we advise you to contact our 24 hour 
              Emergency Assistance Team for any queries and assistance in departing 
              Israel as soon as possible – refer to your policy document for contact 
              details.
            </p>
            <p>
              Limits, conditions and exclusions apply under your policy. Please 
              refer to the Product Disclosure Statement and your Certificate of 
              Insurance for full details.
            </p>
          </div>

          <div class="page-content-info">
            <h3 class="text-center">
              Important Update - Israel and the Occupied Palestinian Territories
              - What you need to know
            </h3>
            <p>
              On 7 October, Israel declared a state of war in response to a
              reported attack by Hamas. Thousands have been killed or injured,
              and hostages have been taken. At the date of publication of this
              advice, the military conflict remains ongoing. The situation is
              highly challenging and rapidly changing and Australians in the
              zone are strongly encouraged to leave.
            </p>
            <p>
              We understand that customers have many questions and concerns
              about their travel insurance coverage whether they are currently
              in Israel or planning to travel.
            </p>
            <p>
              The following Frequently Asked Questions (FAQs) have been
              developed to assist in providing clarity on situations you may
              find yourself in, and how those situations relate to your travel
              insurance coverage.
            </p>
            <p>
              All coverage is assessed on the standard terms and conditions of
              your policy.
            </p>
            <p>
              Please be aware that there are general exclusions within your
              policy with respect to any claims arising out of “Acts of War” and
              regarding travel to areas subject to Do Not Travel status.
            </p>
          </div>

          <div class="page-content-info mb-0">
            <h3>What is the current status?</h3>
            <p>As of 21 October 2023, the DFAT advice is:</p>
            <ul>
              <li>
                <strong>Do Not Travel</strong> to the West Bank (excluding East
                Jerusalem) and to the border areas with Lebanon due to the
                volatile security situation; and
              </li>
              <li>
                <strong>Reconsider your need to travel</strong> to Israel and
                the Occupied Palestinian Territories overall.
              </li>
            </ul>
          </div>

          <div class="page-content-info">
            <!-- Accordion -->
            <h5 class="mb-3 fw-bold ms-2">I have started my trip</h5>

            <div class="card rounded-40 mb-3 shadow border-0">
              <div
                class="faqs-accorion accordion accordion-flush"
                id="faqsAccordion"
              >
                <div class="accordion-item bg-transparent">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title rounded-top-10"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_01"
                      (click)="toggleCollapse($event, 'faqsContent_01')"
                    >
                      What coverage do I have if I am in a conflict affected
                      area?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_01"
                    class="accordion-collapse collapse show"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          For more information on coverage see the table below.
                        </p>
                        <table class="table table-bordered">
                          <tbody>
                            <tr>
                              <td></td>
                              <td>
                                <p>
                                  <strong
                                    >Policies Purchased from 7 October
                                    2023</strong
                                  >
                                </p>
                              </td>
                              <td>
                                <p>
                                  <strong
                                    >Policies purchased prior to 7 October
                                    2023</strong
                                  >
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>
                                  <strong
                                    >Customer travelling within Do Not Travel
                                    (DNT) areas</strong
                                  >
                                </p>
                              </td>
                              <td>
                                <p>
                                  Polices do not cover travel to areas that are
                                  subject to Do Not Travel warnings.<strong
                                    >Therefore any injury, loss or claim
                                    relating to acustomer’stravel to Gaza or the
                                    West Bank at this time while the DNT warning
                                    applies</strong
                                  > will not be covered.
                                </p>
                              </td>
                              <td>
                                <p>
                                  For Customers already located in DNT areas who
                                  purchased policies prior to 7 October 2023,
                                  individual cases will need to be assessed on a
                                  case by case basis. Please contact +61 2 8055
                                  1697.
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>
                                  <strong
                                    >Customers travelling within Israel
                                    (excluding DNT areas)</strong
                                  >
                                </p>
                              </td>
                              <td>
                                <p>
                                  Customers who are currently in Israel are
                                  encouraged to contact our emergency assistance
                                  team to discuss options in relation to
                                  returning to Australia.
                                </p>
                                <p></p>
                                <p></p>
                                <p>
                                  Individual claims will need to be assessed on
                                  a case by case basis. Please refer any such
                                  instances to +61 2 8055 1697.
                                </p>
                              </td>
                              <td>
                                <p>
                                  Customers who are currently in Israel are
                                  encouraged to contact our emergency assistance
                                  team to discuss options in relation to
                                  returning to Australia.
                                </p>
                                <p></p>
                                <p>
                                  Individual claims will need to be assessed on
                                  a case by case basis. Please refer any such
                                  instances to +61 2 8055 1697.
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_02"
                      (click)="toggleCollapse($event, 'faqsContent_02')"
                    >
                      If I choose to remain in Israel will I continue to be
                      covered?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_02"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p style="margin-top: 0cm">
                          If you purchased a policy prior to 7 October and have
                          chosen to remain in Israel, you should understand that
                          any coverage you have does not extend to any claims
                          arising from or relating to any act of war. If you are
                          injured you may not be covered. In addition, as the
                          situation is evolving there may be further extensions
                          of the DNT warnings to wider areas of Israel. For any
                          questions you have in relation to your existing cover
                          please contact
                          <a
                            href="tel:+61 2 8055 1697"
                            class="font-20 mx-2 fw-semibold"
                            >+61 2 8055 1697</a
                          >.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_03"
                      (click)="toggleCollapse($event, 'faqsContent_03')"
                    >
                      I have decided to stay in Israel but my policy is about to
                      expire. Can I extend my cover?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_03"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          If you are requiring an extension of existing cover
                          and you are situated in Israel please contact +61 2
                          8055 1697 to discuss your circumstances.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_04"
                      (click)="toggleCollapse($event, 'faqsContent_04')"
                    >
                      Currently I am in another Middle Eastern country and would
                      like to interrupt my trip due to events in Israel. Would I
                      be able to claim?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_04"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          At first instance we recommend that customers who wish
                          to interrupt or reschedule travel arrangements contact
                          their airline or tour operator as they may have
                          available options relating to cancellation of trips.
                          Many travel suppliers are waiving change fees and/or
                          offering refunds or credits for future travel when
                          traveling to Israel.
                        </p>
                        <p>
                          If your trip is affected and you wish to submit a
                          claim for consideration you can do so via:
                        </p>
                        <p>
                          Web:
                          <a
                            routerLink="/how-to-claim"
                            class="font-20 mx-2 fw-semibold"
                            target="_blank"
                            >how-to-claim</a
                          >
                          <br />
                          Phone:
                          <a
                            href="tel:1300 209 352"
                            class="font-20 mx-2 fw-semibold"
                            >1300 209 352</a
                          >
                          <br />
                          Email:
                          <a
                            href="mailto:racclaims@tmnfatravelinsurance.com.au"
                            class="font-20 mx-2 fw-semibold"
                            >racclaims&#64;tmnfatravelinsurance.com.au</a
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h5 class="mt-5 mb-3 fw-bold ms-2">I have not started my trip</h5>

            <div class="card rounded-40 mb-3 shadow border-0">
              <div class="faqs-accorion accordion" id="faqsAccordionNot">
                <div class="accordion-item bg-transparent">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title rounded-top-10"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_011"
                      (click)="toggleCollapse($event, 'faqsContent_011')"
                    >
                      Can I purchase a new policy for travel to Israel?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_011"
                    class="accordion-collapse collapse show"
                    data-bs-parent="#faqsAccordionNot"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          We are at this time continuing to issue policies to
                          travel to Israel, however, your ability to recover
                          under any such policies may be limited. Please contact
                          1300 655 179 to discuss your circumstance further.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_05"
                      (click)="toggleCollapse($event, 'faqsContent_05')"
                    >
                      Can I claim for cancellation of flights and accommodation?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_05"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordionNot"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-3">
                        <p>
                          At first instance we recommend that customers who are
                          due to travel to Israel in the coming days or weeks
                          contact their airline or tour operator as they may
                          have available options relating to cancellation of
                          trips. Many travel suppliers are waiving change fees
                          and/or offering refunds or credits for future travel
                          when traveling to Israel.
                        </p>
                        <p>
                          If you are seeking to claim for cancellation of travel
                          we will be requesting information from you regarding
                          any refunds or credits received from your airline or
                          tour operator. Please ensure you are ready to provide
                          this information to us before you commence your claim.
                        </p>
                        <p>
                          For more information please contact
                          <a
                            href="tel:1300 209 352"
                            class="font-20 mx-2 fw-semibold"
                            >1300 209 352</a
                          >.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_06"
                      (click)="toggleCollapse($event, 'faqsContent_06')"
                    >
                      If I decide to reschedule my trip, can I transfer my
                      insurance to a later date?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_06"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordionNot"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          Most policies will allow a change of travel dates.
                          Please contact 1300 209 352 to discuss rescheduling.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_07"
                      (click)="toggleCollapse($event, 'faqsContent_07')"
                    >
                      Can I get a refund?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_07"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordionNot"
                  >
                    <div class="accordion-body">
                      <p class="text-gray">
                        Depending on your circumstances you may be entitled to a
                        full or partial refund. Please call
                        <a
                          href="tel:1300 655 179"
                          class="font-20 mx-2 fw-semibold"
                          >1300 655 179</a
                        >
                        to discuss.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_08"
                      (click)="toggleCollapse($event, 'faqsContent_08')"
                    >
                      Will I be covered by my policy if I travel to Israel
                      despite the current situation?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_08"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordionNot"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          If you purchased a policy prior to 7 October and chose
                          to travel to Israel, you should understand that any
                          coverage you have does not extend to any claims
                          arising from or relating to any act of war. In
                          addition, as the situation is evolving there may be
                          further extensions of the DNT warnings to wider areas
                          of Israel. For any questions you have in relation to
                          your existing cover please contact 1300 655 179.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_09"
                      (click)="toggleCollapse($event, 'faqsContent_09')"
                    >
                      Where can I find other updates and information?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_09"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordionNot"
                  >
                    <div class="accordion-body">
                      <p>
                        Stay up to date on travel information from the
                        Department of Foreign Affairs and Trade (DFAT)&nbsp;<a
                          href="https://www.smartraveller.gov.au/"
                          target="_blank"
                          >Smartraveller</a
                        >&nbsp;website.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-racv-footer></app-racv-footer>
</div>
