import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CxOneModel } from './cxone.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentCXOneService {

  private cxOneCallStatus = new BehaviorSubject<CxOneModel>({ isCallEnabled :  false , maskCallTriggered : false});

  cxOneCallStatus$ = this.cxOneCallStatus.asObservable();

  constructor(
 
  ) {
   
  }
  updateCxOneCallStatus(cxOneModel: CxOneModel) {
    this.cxOneCallStatus.next(cxOneModel);
  }
}
