import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  RouterOutlet,
  RouterLink,
  RouterLinkActive,
  Router,
  NavigationEnd,
} from '@angular/router';

import { LoadingMaskComponent } from '../../components/loading-mask/loading-mask.component';
import { EmailQuoteComponent } from '../../components/email-quote/email-quote.component';

import { GlobalService } from '../../services/global.service';
import { filter } from 'rxjs/operators';
import { SegmentService } from '../../services/segment.service';
import { GAService } from '../../services/ga.service';
import { ToastModule } from 'primeng/toast';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    LoadingMaskComponent,
    EmailQuoteComponent,
    ToastModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  previousPathClass: string = '';
  showLoadingMask$ = this.globalService.showLoadingMask$;
  constructor(
    public globalService: GlobalService,
    private router: Router,
    private segmentService: SegmentService,
    private gaService: GAService,
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.segmentService.pageLoad();
        this.updateBodyClass(event.urlAfterRedirects);
        this.gaService.pageLoad();
      });
  }
  updateBodyClass(url: string) {
    console.log('url change subscribe', url);
    const body = document.body;
    body.classList.remove('modal-open');
    body.removeAttribute('style');
    body.removeAttribute('data-bs-overflow');
    body.removeAttribute('data-bs-padding-right');
    const modalBackdrops = document.querySelectorAll(
      '.modal-backdrop.fade.show',
    );
    modalBackdrops.forEach((modalBackdrop) => modalBackdrop.remove());
  }
}
