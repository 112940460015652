import { Injectable } from '@angular/core';
import { InMemoryDbService } from 'angular-in-memory-web-api';
import { Country } from '../interfaces/country';

@Injectable({
  providedIn: 'root',
})
export class InMemoryDataService implements InMemoryDbService {
  createDb() {
    const countries = [
      {
        regionId: 0,
        displayRegionName: 'Africa',
        ratingRegionName: 'Africa',
        countryCode: '',
        countryName: 'South Africa',
        allowTravel: true,
        hasCalamity: true,
        calamityDescription: '',
        sortOrder: 0,
        helixScore: 0,
        isDomestic: false,
        isPASAMT: false,
        riskCategory: '',
      },
      {
        regionId: 1,
        displayRegionName: 'Americas',
        ratingRegionName: 'Americas',
        countryCode: '',
        countryName: 'Canada',
        allowTravel: true,
        hasCalamity: true,
        calamityDescription: '',
        sortOrder: 0,
        helixScore: 0,
        isDomestic: false,
        isPASAMT: false,
        riskCategory: '',
      },
      {
        regionId: 2,
        displayRegionName: 'Americas',
        ratingRegionName: 'Americas',
        countryCode: '',
        countryName: 'United States of America',
        allowTravel: true,
        hasCalamity: true,
        calamityDescription: '',
        sortOrder: 0,
        helixScore: 0,
        isDomestic: false,
        isPASAMT: false,
        riskCategory: '',
      },
      {
        regionId: 3,
        displayRegionName: 'Asia',
        ratingRegionName: 'Asia',
        countryCode: '',
        countryName: 'Hong Kong',
        allowTravel: true,
        hasCalamity: true,
        calamityDescription: '',
        sortOrder: 0,
        helixScore: 0,
        isDomestic: false,
        isPASAMT: false,
        riskCategory: '',
      },
      {
        regionId: 4,
        displayRegionName: 'Asia',
        ratingRegionName: 'Asia',
        countryCode: '',
        countryName: 'Japan',
        allowTravel: true,
        hasCalamity: true,
        calamityDescription: '',
        sortOrder: 0,
        helixScore: 0,
        isDomestic: false,
        isPASAMT: false,
        riskCategory: '',
      },
      {
        regionId: 5,
        displayRegionName: 'Asia',
        ratingRegionName: 'Asia',
        countryCode: '',
        countryName: 'Malaysia',
        allowTravel: true,
        hasCalamity: true,
        calamityDescription: '',
        sortOrder: 0,
        helixScore: 0,
        isDomestic: false,
        isPASAMT: false,
        riskCategory: '',
      },
      {
        regionId: 6,
        displayRegionName: 'Asia',
        ratingRegionName: 'Asia',
        countryCode: '',
        countryName: 'Singapore',
        allowTravel: true,
        hasCalamity: true,
        calamityDescription: '',
        sortOrder: 0,
        helixScore: 0,
        isDomestic: false,
        isPASAMT: false,
        riskCategory: '',
      },
      {
        regionId: 7,
        displayRegionName: 'Asia',
        ratingRegionName: 'Asia',
        countryCode: '',
        countryName: 'Thailand',
        allowTravel: true,
        hasCalamity: true,
        calamityDescription: '',
        sortOrder: 0,
        helixScore: 0,
        isDomestic: false,
        isPASAMT: false,
        riskCategory: '',
      },
      {
        regionId: 8,
        displayRegionName: 'Europe',
        ratingRegionName: 'Europe',
        countryCode: '',
        countryName: 'France',
        allowTravel: true,
        hasCalamity: true,
        calamityDescription: '',
        sortOrder: 0,
        helixScore: 0,
        isDomestic: false,
        isPASAMT: false,
        riskCategory: '',
      },
      {
        regionId: 9,
        displayRegionName: 'Europe',
        ratingRegionName: 'Europe',
        countryCode: '',
        countryName: 'Germany',
        allowTravel: true,
        hasCalamity: true,
        calamityDescription: '',
        sortOrder: 0,
        helixScore: 0,
        isDomestic: false,
        isPASAMT: false,
        riskCategory: '',
      },
      {
        regionId: 10,
        displayRegionName: 'Europe',
        ratingRegionName: 'Europe',
        countryCode: '',
        countryName: 'Italy',
        allowTravel: true,
        hasCalamity: true,
        calamityDescription: '',
        sortOrder: 0,
        helixScore: 0,
        isDomestic: false,
        isPASAMT: false,
        riskCategory: '',
      },
      {
        regionId: 11,
        displayRegionName: 'Europe',
        ratingRegionName: 'Europe',
        countryCode: '',
        countryName: 'Netherlands',
        allowTravel: true,
        hasCalamity: true,
        calamityDescription: '',
        sortOrder: 0,
        helixScore: 0,
        isDomestic: false,
        isPASAMT: false,
        riskCategory: '',
      },
      {
        regionId: 12,
        displayRegionName: 'Europe',
        ratingRegionName: 'Europe',
        countryCode: '',
        countryName: 'Spain',
        allowTravel: true,
        hasCalamity: true,
        calamityDescription: '',
        sortOrder: 0,
        helixScore: 0,
        isDomestic: false,
        isPASAMT: false,
        riskCategory: '',
      },
      {
        regionId: 13,
        displayRegionName: 'Europe',
        ratingRegionName: 'Europe',
        countryCode: '',
        countryName: 'Switzerland',
        allowTravel: true,
        hasCalamity: true,
        calamityDescription: '',
        sortOrder: 0,
        helixScore: 0,
        isDomestic: false,
        isPASAMT: false,
        riskCategory: '',
      },
      {
        regionId: 14,
        displayRegionName: 'Europe',
        ratingRegionName: 'Europe',
        countryCode: '',
        countryName: 'United Kingdom',
        allowTravel: true,
        hasCalamity: true,
        calamityDescription: '',
        sortOrder: 0,
        helixScore: 0,
        isDomestic: false,
        isPASAMT: false,
        riskCategory: '',
      },
      {
        regionId: 15,
        displayRegionName: 'Pacific',
        ratingRegionName: 'Pacific',
        countryCode: '',
        countryName: 'Australia',
        allowTravel: true,
        hasCalamity: true,
        calamityDescription: '',
        sortOrder: 0,
        helixScore: 0,
        isDomestic: true,
        isPASAMT: false,
        riskCategory: '',
      },
      {
        regionId: 16,
        displayRegionName: 'Pacific',
        ratingRegionName: 'Pacific',
        countryCode: '',
        countryName: 'Australian Cruises',
        allowTravel: true,
        hasCalamity: true,
        calamityDescription: '',
        sortOrder: 0,
        helixScore: 0,
        isDomestic: true,
        isPASAMT: false,
        riskCategory: '',
      },
      {
        regionId: 17,
        displayRegionName: 'Pacific',
        ratingRegionName: 'Pacific',
        countryCode: '',
        countryName: 'Bali',
        allowTravel: true,
        hasCalamity: true,
        calamityDescription: '',
        sortOrder: 0,
        helixScore: 0,
        isDomestic: false,
        isPASAMT: false,
        riskCategory: '',
      },
      {
        regionId: 18,
        displayRegionName: 'Pacific',
        ratingRegionName: 'Pacific',
        countryCode: '',
        countryName: 'Fiji',
        allowTravel: true,
        hasCalamity: true,
        calamityDescription: '',
        sortOrder: 0,
        helixScore: 0,
        isDomestic: false,
        isPASAMT: false,
        riskCategory: '',
      },
      {
        regionId: 19,
        displayRegionName: 'Pacific',
        ratingRegionName: 'Pacific',
        countryCode: '',
        countryName: 'Indonesia',
        allowTravel: true,
        hasCalamity: true,
        calamityDescription: '',
        sortOrder: 0,
        helixScore: 0,
        isDomestic: false,
        isPASAMT: false,
        riskCategory: '',
      },
      {
        regionId: 20,
        displayRegionName: 'Pacific',
        ratingRegionName: 'Pacific',
        countryCode: '',
        countryName: 'New Zealand',
        allowTravel: true,
        hasCalamity: true,
        calamityDescription: '',
        sortOrder: 0,
        helixScore: 0,
        isDomestic: false,
        isPASAMT: false,
        riskCategory: '',
      },
      {
        regionId: 21,
        displayRegionName: 'Pacific',
        ratingRegionName: 'Pacific',
        countryCode: '',
        countryName: 'Vanuatu',
        allowTravel: true,
        hasCalamity: true,
        calamityDescription: '',
        sortOrder: 0,
        helixScore: 0,
        isDomestic: false,
        isPASAMT: false,
        riskCategory: '',
      },
    ];

    const cities = [
      { id: 12, name: 'Hongkong', state: 'Asia' },
      { id: 13, name: 'Tokyo', state: 'Asia' },
    ];

    const allBenefits = [
      {
        id: '1',
        benefits:
          'Cancellation Fees and Lost Deposits# - Unforeseen Circumstances#',
        annual: '$unlimited^^ per Adult',
        top: '$unlimited^^ per Adult',
        essentials: '$25,000 per Adult',
        basics: 'Not Applicable',
        domestic: '$25,000 per Adult',
        details:
          'Your Cancellation Fees and Lost Deposits for Your travel and accommodation arrangements that you have pre-paid and cannot recover in any other way if your trip is cancelled or cut short at any time, through severe circumstances beyond your control that you did not expect or intend.',
      },
      {
        id: '2',
        benefits:
          'Death, Injury or Illness of Your Relative, Your Travelling Companion or Your Travelling Companion’s Relative#',
        annual: '$1,000*** per Adult',
        top: '$1,000*** per Adult',
        essentials: '$1,000*** per Adult',
        basics: 'Not Applicable',
        domestic: '$1,000*** per Adult',
        details:
          'A benefit for cancellation fees and lost deposits for Your travel and accommodation arrangements, excursions, tours, and other activities, subject to sub limits and conditions due to the death, injury or illness of Your Relative, Your Travelling Companion or Your Travelling Companion’s Relative.',
      },
      {
        id: '3',
        benefits: 'Emergency Worker Leave+ (Sub Limit)',
        annual: '$1,000 per Adult',
        top: '$1,000 per Adult',
        essentials: '$500 per Adult',
        basics: 'Not Applicable',
        domestic: '$750 per Adult',
        details:
          'The cost of prepaid Cancellation Fees and Lost Deposits if your annual leave is cancelled by your employer, you are an employee of the armed forces, police, fire or ambulance services and you can no longer go on your trip. Refer to the PDS for other conditions associated with the Sub Limit.',
      },
      {
        id: '4',
        benefits: 'Travel Agent Fees+ (Sub Limit)',
        annual: '$4,000 per Adult',
        top: '$4,000 per Adult',
        essentials: '$2,000 per Adult',
        basics: 'Not Applicable',
        domestic: '$2,000 per Adult',
        details:
          'Non-recoverable fees you have been charged by your travel agent.',
      },
      {
        id: '5',
        benefits: 'Overseas Medical and Hospital',
        annual: '$unlimited** per Adult',
        top: '$unlimited** per Adult',
        essentials: '$unlimited** per Adult',
        basics: '$unlimited** per Adult',
        details:
          'Reasonable overseas medical and hospital expenses you have to pay as a result of an injury or illness.',
      },
      {
        id: '6',
        benefits: 'Cash in Hospital#+ (Sub Limit)',
        annual: '$6,000 per Adult',
        top: '$6,000 per Adult',
        essentials: '$3,500 per Adult',
        basics: '$1,500 per Adult',
        details:
          'In addition to medical expenses, if you are hospitalised overseas for over 48 hours, you will receive $50 from us for every 24 hours you are in hospital from your first day of hospitalisation. This can be used for out-of-pocket expenses and other creature comforts.',
      },
      {
        id: '7',
        benefits: 'Overseas Dental#+ (Sub Limit)',
        annual: '$2,000 per Adult',
        top: '$2,000 per Adult',
        essentials: '$2,000 per Adult',
        basics: '$500 per Adult',
        details:
          'This provides for emergency overseas dental expenses incurred following an injury to sound and natural teeth caused solely and directly by external and visible means as a result of an accident and which does not result from an illness or disease, but not treatment that can be delayed until you return to Australia. We also cover up to $500 for emergency dental costs for relief of sudden and acute pain given or prescribed by a legally qualified practitioner and necessarily incurred outside Australia.',
      },
      {
        id: '8',
        benefits: 'Funeral Expenses Overseas*+ (Sub Limit)',
        annual: '$20,000 per person',
        top: '$20,000 per person',
        essentials: '$15,000 per person',
        basics: '$10,000 per person',
        details:
          'Funeral expenses, if you or your dependents die while overseas.',
      },
      {
        id: '9',
        benefits: 'Luggage#',
        annual: '$15,000 per Adult',
        top: '$15,000 per Adult',
        essentials: '$7,500 per Adult',
        basics: '$2,000 per Adult',
        domestic: '$7,500 per Adult',
        details:
          'We will cover you for the loss, theft or damage to your Luggage. This includes duty-free purchases, as well as the loss or fraudulent use of credit cards and travel documents. Our policy covers, new for old replacement of Luggage.',
      },
      {
        id: '10',
        benefits: 'Travel Documents+ (Sub Limit)',
        annual: '$2,000 per Adult',
        top: '$2,000 per Adult',
        essentials: '$1,500 per Adult',
        basics: 'Not Applicable',
        details:
          'If your travel documents or credit cards carried with you are lost or illegally used by someone other than you.',
      },
      {
        id: '11',
        benefits: 'Luggage Delay+ (Sub Limit)',
        annual: '$750 per Adult',
        top: '$750 per Adult',
        essentials: '$500 per Adult',
        basics: 'Not Applicable',
        domestic: '$500 per Adult',
        details:
          'If you have to buy essential clothing and personal items overseas because luggage carried by your transport provider overseas is delayed by more than 10 hours.',
      },
      {
        id: '12',
        benefits: 'Personal Money+ (Sub Limit)',
        annual: '$500 per Adult',
        top: '$500 per Adult',
        essentials: '$250 per Adult',
        basics: 'Not Applicable',
        domestic: '$250 per Adult',
        details:
          'If your personal money is stolen whilst on your trip, provided you can provide proof of ownership.',
      },
      {
        id: '13',
        benefits: 'Emergency Expenses#',
        annual: '$unlimited^^ per Adult',
        top: '$unlimited^^ per Adult',
        essentials: '$25,000 per Adult',
        basics: '$5,000 per Adult',
        domestic: '$25,000 per Adult',
        details:
          'We will also cover you for additional accommodation, meal and travelling expenses including emergency personal telephone calls, if your trip is disrupted in certain circumstances.',
      },
      {
        id: '14',
        benefits: 'Resumption of Travel+ (Sub Limit)',
        annual: '$3,000 per Adult',
        top: '$3,000 per Adult',
        essentials: '$1,500 per Adult',
        basics: 'Not Applicable',
        details:
          'We will also pay your transport costs to resume your original overseas trip if you rejoin it within 60 days of returning to Australia providing there was at least 7 days of your trip remaining.',
      },
      {
        id: '15',
        benefits:
          'Emergency Expenses for Death, Injury or Illness of Your Relative, Travelling Companion or Your Travelling Companion’s Relative',
        annual: '$3,000*** per Adult',
        top: '$3,000*** per Adult',
        essentials: '$1,500*** per Adult',
        basics: 'Not Applicable',
        domestic: 'Not Applicable',
        details:
          'We will pay Your emergency expenses subject to sub limits and conditions if Your Trip is rescheduled, cancelled, or cut short at any time because of the death, injury, or illness of Your Relative, Your Travelling Companion or Your Travelling Companion’s Relative.',
      },
      {
        id: '16',
        benefits: 'Special Events+ (Sub Limit)',
        annual: '$5,000 per Adult',
        top: '$5,000 per Adult',
        essentials: '$2,000 per Adult',
        basics: 'Not Applicable',
        details:
          "If you are attending a prearranged special event that can't be delayed because of something unexpected and outside your control, we will pay you the reasonable additional cost to use alternative public transport to arrive at your destination on time.",
      },
      {
        id: '17',
        benefits: 'Accidental Death*',
        annual: '$25,000 per person',
        top: '$25,000 per person',
        essentials: '$15,000 per person',
        basics: 'Not Applicable',
        domestic: '$25,000 per person',
        details:
          'If you or your declared dependents die within 12 months as a result of an injury caused by an accident or terrorist act during your trip.',
      },
      {
        id: '18',
        benefits: 'Accidental Disability*',
        annual: '$25,000 per person',
        top: '$25,000 per person',
        essentials: '$15,000 per person',
        basics: 'Not Applicable',
        domestic: '$25,000 per person',
        details:
          'This provides a lump sum benefit if you or your declared dependent suffers a permanent disability, specified in the policy, during your trip due to an accident which we cover.',
      },
      {
        id: '19',
        benefits: 'Your Legal Liability^',
        annual: '$10,000,000 per policy',
        top: '$10,000,000 per policy',
        essentials: '$10,000,000 per policy',
        basics: '$10,000,000 per policy',
        domestic: '$10,000,000 per policy',
        details:
          'This covers claims against you arising from an accident that happened on your trip, causing: a) injury or death to someone other than you, b) loss of property not owned or controlled by you, c) damage to property not owned or controlled by you. In addition, it provides cover for reasonable legal expenses.',
      },
      {
        id: '20',
        benefits: 'Rental Vehicle Excess#',
        annual: '$5,000 per policy',
        top: '$5,000 per policy',
        essentials: '$4,000 per policy',
        basics: 'Not Applicable',
        domestic: '$5,000 per Adult',
        details:
          'When you hire a car we will cover the excess applied by the rental car company in case of accident, damage or theft.',
      },
      {
        id: '21',
        benefits: 'Loss of Income from Injury#',
        annual: '$10,400 per Adult',
        top: '$10,400 per Adult',
        essentials: '$5,200 per Adult',
        basics: 'Not Applicable',
        domestic: '$5,200 per Adult',
        details:
          'If you are unable to go back to work after returning home, due to an accident or terrorist act that occurred whilst on your trip, we will cover you for the loss of your usual permanent income to a limit 26 weeks.',
      },
      {
        id: '22',
        benefits: 'Financial Default#',
        annual: '$10,000 per Adult',
        top: '$10,000 per Adult',
        essentials: '$5,000 per Adult',
        basics: 'Not Applicable',
        domestic: '$3,000 per Adult',
        details:
          'This provides assistance if your travel services provider (excluding travel agents, tour wholesaler, tour operator, or booking agent) suffers an event of financial default.',
      },
      {
        id: '23',
        benefits: 'Domestic Pets^#',
        annual: '$650 per policy',
        top: '$650 per policy',
        essentials: 'Not Applicable',
        basics: 'Not Applicable',
        details:
          'We will cover you for additional boarding kennel or cattery fees if you are delayed from returning home due to a claimable event. In addition we cover the veterinary fees if your pet is injured while you are away.',
      },
      {
        id: '24',
        benefits: 'Domestic Services^#',
        annual: '$1,500 per policy',
        top: '$1,500 per policy',
        essentials: 'Not Applicable',
        basics: 'Not Applicable',
        details:
          'If you are injured during your trip and become disabled as a result of the injury and the disablement continues after your return to home, we will reimburse you up to $50 per day in respect of expenses incurred in the provision of housekeeping services that you are unable to fully perform yourself.',
      },
      {
        id: '25',
        benefits: 'Travel Delay#',
        annual: '$3,000 per Adult',
        top: '$3,000 per Adult',
        essentials: '$1,500 per Adult',
        basics: 'Not Applicable',
        domestic: '$1,500 per Adult',
        details:
          'We provide cover for additional meals and accommodation expenses, after an initial 6-hour delay, if your trip is disrupted due to circumstances beyond your control.',
      },
      {
        id: '26',
        benefits: 'Hijack and Kidnap*#',
        annual: '$10,000 per person',
        top: '$10,000 per person',
        essentials: '$5,000 per person',
        basics: 'Not Applicable',
        details:
          'This provides you a daily amount for each day you, your children and grandchildren are held captive, following being hijacked or kidnapped.',
      },
      {
        id: '27',
        benefits:
          'Optional Cover Ski & Winter Sports Overseas Medical and Hospital',
        annual: '$unlimited**',
        top: '$unlimited**',
        essentials: '$unlimited**',
        basics: 'Not Applicable',
        domestic: 'Not Applicable',
        details:
          'We will provide coverage for the reasonable expenses incurred overseas, including medical and hospital expenses resulting from an injury sustained during participation in Skiing or Winter Sports. This benefit is subject to certain limits and conditions.',
      },
      {
        id: '28',
        benefits: 'Optional Cover – Ski and Winter Sports - Equipment Hire^',
        annual: '$2,000 per policy',
        top: '$2,000 per policy',
        essentials: '$1,000 per policy',
        basics: 'Not Applicable',
        domestic: '$1,000 per policy',
        details:
          'This provides for the hire of replacement snow equipment when yours is stolen and we accept coverage set out under Section 3 ‘Luggage’ in the PDS, provided you have complied with the policy terms.',
      },
      {
        id: '29',
        benefits: 'Optional Cover – Ski and Winter Sports - Ski Pack^',
        annual: '$1,000 per policy',
        top: '$1,000 per policy',
        essentials: '$500 per policy',
        basics: 'Not Applicable',
        domestic: '$500 per policy',
        details:
          'This covers the value of any unused ski passes, ski hire, tuition fees or lift passes you cannot use due to injury or illness.',
      },
      {
        id: '30',
        benefits: 'Optional Cover – Ski and Winter Sports - Piste Closure^#',
        annual: '$1,000 per policy',
        top: '$1,000 per policy',
        essentials: '$500 per policy',
        basics: 'Not Applicable',
        details:
          'This covers you for travel to an alternate resort or for the purchase of additional ski passes if you are unable to ski at your pre-booked resort due to piste closure.',
      },
      {
        id: '31',
        benefits:
          'Optional Cover – Ski and Winter Sports - Bad Weather and Avalanche^',
        annual: '$1,000 per policy',
        top: '$1,000 per policy',
        essentials: '$500 per policy',
        basics: 'Not Applicable',
        domestic: '$500 per policy',
        details:
          'If your outward or return trip is delayed for more than 12 hours due to an avalanche or bad weather, we will cover your travel accommodation.',
      },
      {
        id: '32',
        benefits: 'Optional Cover – Ski and Winter Sports - Lift Pass',
        annual: '$500 per Adult',
        top: '$500 per Adult',
        essentials: '$250 per Adult',
        basics: 'Not Applicable',
        domestic: '$250 per Adult',
        details:
          'This covers your cost of purchasing a new ski lift pass if your ski lift pass is lost or stolen provided you comply with the policy terms and conditions as set out in the PDS.',
      },
      {
        id: '33',
        benefits: 'COVID-19 Overseas Medical and Hospital Emergency Expenses#',
        annual: '$Unlimited**',
        top: '$Unlimited**',
        essentials: 'Not Applicable',
        basics: 'Not Applicable',
        details:
          'Reasonable overseas medical, hospital and related expenses you have to pay as a result of a diagnosis of COVID-19.',
      },
      {
        id: '34',
        benefits: 'COVID-19 Cancellation Fees and Lost Deposits',
        annual: '$3,500 combined limit per Adult',
        top: '$3,500 combined limit per Adult',
        essentials: 'Not Applicable',
        basics: 'Not Applicable',
        domestic: '$3,500 combined limit per Adult',
        details:
          'Your Cancellation Fees and Lost Deposits for travel and accommodation arrangements that you have pre-paid and cannot recover in any other way if your trip is cancelled or cut short at any time, due to the diagnosis of COVID-19.',
      },
      {
        id: '35',
        benefits: 'COVID-19 Additional Expenses# and Special Events',
        annual: '$3,500 combined limit per Adult',
        top: '$3,500 combined limit per Adult',
        essentials: 'Not Applicable',
        basics: 'Not Applicable',
        domestic: '$3,500 combined limit per Adult',
        details:
          "Additional expenses for certain scenarios related to COVID-19. Also if you are attending a prearranged special event that can't be delayed because of something related to COVID-19 and outside your control, we will pay you the reasonable additional cost to use alternative public transport to arrive at your destination on time.",
      },
      {
        id: '36',
        benefits: 'COVID-19 Travel Delay#',
        annual: '$3,500 combined limit per Adult',
        top: '$3,500 combined limit per Adult',
        essentials: 'Not Applicable',
        basics: 'Not Applicable',
        domestic: '$3,500 combined limit per Adult',
        details:
          'We provide cover for additional meals and accommodation expenses, after an initial 6-hour delay, if your trip is disrupted due to COVID-19.',
      },
    ];

    const racvwording = [
      {
        id: 1,
        page: 'step-one',
        field: 'getaquote',
        wording: ' Get a Quote',
      },
    ];

    return {
      countries,
      cities,
      allBenefits,
      racvwording,
    };
  }

  // Overrides the genId method to ensure that a country always has an id.
  // If the countries array is empty,
  // the method below returns the initial number (11).
  // if the countries array is not empty, the method below returns the highest
  // country id + 1.
  genId<T extends Country>(countries: T[]): number {
    return countries.length > 0
      ? Math.max(...countries.map((country) => country.regionId)) + 1
      : 11;
  }
}
