import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, HostBinding } from '@angular/core';
import { RouterLink } from '@angular/router';
import { PDSService } from '../../services/pds.service';
import { GlobalService } from '../../services/global.service';
import { LocalCacheService } from '../../services/localCache.service';
import { environment } from '../../../environments/environment';
import { CXOneService } from '../../services/cxone/cxone.service';

@Component({
  selector: 'app-racv-common-header',
  standalone: true,
  imports: [RouterLink, CommonModule],
  templateUrl: './racv-common-header.component.html',
  styleUrl: './racv-common-header.component.scss',
})
export class RacvCommonHeaderComponent implements OnInit {
  @Input() activeHeader: string = '';
  pdsLink: string = '';
  agentName: string = '';
  isPASUser: boolean = false;
  PASUrl: string = '';

  @HostBinding('class.pas-user')
  get pasUserClass() {
    return this.isPASUser;
  }

  constructor(
    private globalService: GlobalService,
    private localCacheService: LocalCacheService,
    private pdsService: PDSService,
    private cxoneService: CXOneService
  ) {
    this.PASUrl = environment.PASUrl;
  }
  ngOnInit(): void {
    var agentGuid =
      this.globalService.getQueryParamInfo('AgentGuid') ||
      this.localCacheService.getSessionStorage('agentGuid');
    this.agentName =
      this.globalService.getQueryParamInfo('AgentName') ||
      this.localCacheService.getSessionStorage('agentName');
    if (this.agentName && agentGuid) {
      this.isPASUser = true;
    }
    const currentLatestPDS =
      this.localCacheService.getSessionStorage('lastestPDS');
    if (!currentLatestPDS) {
      this.pdsService.getLatestPDS().subscribe((data) => {
        if (data && data.pds && data.pds.length > 0) {
          this.pdsLink = data.pds[0].previewUrl;
        }
      });
    } else {
      this.pdsLink = currentLatestPDS?.previewUrl;
    }
  }

  clearSession() {   
    this.cxoneService.validateUnmask();
    setTimeout(() => {
      sessionStorage.clear();
      location.href = this.PASUrl;
    }, 100);
  }
}
