<section class="footer" [ngClass]="dynamicClassName">
  <div class="container-xl footer-top pb-4 mb-4">
    <div class="row">
      <ul class="ft-links">
        <li>
          <a routerLink="/terms-and-conditions" target="_blank"
            >Terms and Conditions</a
          >
        </li>
        <li>
          <a routerLink="/privacy-policy" target="_blank">Privacy Policy</a>
        </li>
        <li>
          <a routerLink="/refunds" target="_blank">Refunds Notice</a>
        </li>
        <li>
          <a
            href="{{ pdsLink }}"
            target="_blank"
            (click)="triggerSegmentEvent('pds_footer_3')"
            >Product Disclosure Statement</a
          >
        </li>
        <li>
          <a routerLink="/how-to-claim" target="_blank">Claims</a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://rac.com.au/travel-touring/travel-insurance/faqs-and-information"
            >Frequently Asked Questions</a
          >
        </li>
        <li>
          <a
            target="_blank"
            href="https://rac.com.au/travel-touring/find-a-travel-centre"
            >Contact Us</a
          >
        </li>
        <li>
          <a
            target="_blank"
            href="http://rac.com.au/about-us/about-this-site/disclaimer"
            >Disclaimer</a
          >
        </li>
        <li>
          <a
            target="_blank"
            href="https://rac.com.au/about-rac/site-info/security"
            >Security</a
          >
        </li>
        <li>
          <a
            target="_blank"
            href="http://rac.com.au/about-us/about-this-site/sitemap"
            >Sitemap</a
          >
        </li>
      </ul>
    </div>
  </div>
  <div class="container-xl footer-bottom">
    <div class="row ps-md-5 ps-2 pe-md-5 pe-2">
      <p class="text-light text-small">
        Tokio Marine & Nichido Fire Insurance Co., Ltd. (ABN 80 000 438 291 AFSL
        246 548) is the issuer of RAC Travel Insurance. The 'RAC Travel
        Insurance - Combined Financial Services Guide and Product Disclosure
        Statement' (PDS) which includes the policy wording, is available
        <a
          href="{{ pdsLink }}"
          target="_blank"
          (click)="triggerSegmentEvent('pds_footer_3')"
          >here</a
        >. Any financial advice provided by RAC which is set out above is
        general in nature only, and does not take into account your objectives,
        financial situation or needs. Because of this, you should, before acting
        on the advice, consider the appropriateness of the advice, having
        regards to your objectives, financial situation and needs. You should
        consider the PDS in deciding whether to acquire, or to continue to hold
        the policies. Where it provides ﬁnancial services in relation to RAC
        Travel Insurance (such as ﬁnancial advice or arranging for the issue of
        insurance), R.A.C. Travel Services Pty. Limited (ABN 17 009 164
        176 AR 228 577) is acting as an Authorised Representative of the issuer
        of the insurance, Tokio Marine & Nichido Fire Insurance Co., Ltd. (ABN
        80 000 438 291 AFSL 246 548). Whilst the PDS outlines the Terms and
        Conditions of these products, the TMD’s outline the intended class of
        customers that comprise the target market for these travel products.
        This will allow you to consider which products best suit your
        objectives, financial situation and needs and consider the products
        appropriateness to your personal circumstances. TMD’s also outline
        matters involving the distribution and the review of these products. The
        TMD’s for each travel product can be found in
        <a
          href="{{ tmdLink }}"
          target="_blank"
          (click)="triggerSegmentEvent('tmd_footer_3')"
          >here</a
        >.
      </p>
    </div>
  </div>
</section>
