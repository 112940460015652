import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  constructor() {}

  chagneTheme(checked: boolean) {
    document.body.className = 'club-theme';
  }
}
