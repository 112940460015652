import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { PDSService } from '../../services/pds.service';
import { LocalCacheService } from '../../services/localCache.service';
import { SegmentService } from '../../services/segment.service';

@Component({
  selector: 'app-racv-footer',
  standalone: true,
  imports: [RouterLink, CommonModule],
  templateUrl: './racv-footer.component.html',
  styleUrl: './racv-footer.component.scss',
})
export class RacvFooterComponent implements OnInit {
  /**
   *
   */
  dynamicClassName: string = '';
  pdsLink: string = '';
  tmdLink: string = '';
  constructor(
    private pdsService: PDSService,
    private router: Router,
    private localCacheService: LocalCacheService,
    private segmentService: SegmentService,
  ) {}
  ngOnInit(): void {
    let path = this.router.url.split('/')?.filter(Boolean)?.join('-');
    if (!path) {
      path = 'homepage';
    }
    this.dynamicClassName = path;
    const currentLatestPDS =
      this.localCacheService.getSessionStorage('lastestPDS');
    if (!currentLatestPDS) {
      this.pdsService.getLatestPDS().subscribe((data) => {
        if (data && data.pds && data.pds.length > 0) {
          this.pdsLink = data.pds[0].previewUrl;
        }
      });
    } else {
      this.pdsLink = currentLatestPDS?.previewUrl;
    }
    const currentLatestTMD =
      this.localCacheService.getSessionStorage('lastestTMD');
    if (!currentLatestTMD) {
      this.pdsService.getLatestTMD().subscribe((data) => {
        if (data && data.tmd && data.tmd.length > 0) {
          data.tmd.forEach((obj: any) => {
            if (obj.isActive) {
              this.tmdLink = obj.url;
            }
          });
        }
      });
    } else {
      this.tmdLink = currentLatestTMD?.url;
    }
  }

  triggerSegmentEvent(field: string) {
    let eventNameTitle: any;
    switch (field) {
      case 'pds_footer_1':
        eventNameTitle = 'footer_pds_1';
        break;
      case 'pds_footer_2':
        eventNameTitle = 'footer_pds_2';
        break;
      case 'pds_footer_3':
        eventNameTitle = 'footer_pds_3';
        break;
      case 'tmd_footer_1':
        eventNameTitle = 'footer_tmd_1';
        break;
      case 'tmd_footer_2':
        eventNameTitle = 'footer_tmd_2';
        break;
      case 'tmd_footer_3':
        eventNameTitle = 'footer_tmd_3';
        break;
      case 'travelInsurancePds':
        eventNameTitle = 'footer_travel_insurance_pds';
        break;
    }

    const pdsAndTmdOnClick = {
      eventName: eventNameTitle,
    };

    this.segmentService.segmentIdentifyEventNameAndValue(pdsAndTmdOnClick);
  }
}
