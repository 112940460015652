<div class="theme-static">
    <section class="header position-fixed w-100">
        <app-racv-common-header></app-racv-common-header>
    </section>

    <!-- End Header Part -->

    <!-- Start Page Header Title Part -->
    <section class="page-header-title">
        <div class="page-title-overlay"></div>
        <img src="assets/images/rac/page-header/travel-alerts-sub-page.png" alt="" class="w-100 page-title-image" />
        <div class="page-header-title-caption">
            <h1>China and Iran - Do Not Travel</h1>
        </div>
    </section>

    <main class="page-content py-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-12"> 
    
                <div class="page-content-info">

                  <p><span>This information is in relation to the ‘Do Not Travel’ Warnings currently in effect for China and Iran.</span></p>
                  <p><span>Due to the risk and spread of Coronavirus, the Australian government updated the travel warnings for China on Saturday 1 February 2020, and Iran on Saturday 29 February 2020, to ‘Do Not Travel’ as advised on <a href="https://www.smartraveller.gov.au">smartraveller.gov.au.</a></span></p>
                  <p><span>All travel insurance policies purchased after the above dates exclude cover to any country with the advice of Do Not Travel and we cannot offer cover for these destinations. This means that if you purchase a policy to China or Iran whilst the Do Not Travel restrictions are in place, there is no cover for any claims related to Coronavirus.</span></p>	
                </div>
    
            </div>
    
            
          </div>
        </div>
      </main>
       

    <app-racv-footer></app-racv-footer>
</div>