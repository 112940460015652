import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JsonDataService } from './json-data.service';

import { Observable, map } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ValidationService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(
    private http: HttpClient,
    private jsonDataService: JsonDataService,
  ) {}

  clubValidation(): Observable<any[]> {
    switch (environment.autoClub) {
      case 'RAC':
        return this.jsonDataService
          .getValidationJsonData()
          .pipe(map((validation) => validation.racvalidation));
      default:
        return this.jsonDataService.getValidationJsonData();
    }
  }
}
