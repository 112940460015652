export const environment = {
  production: true,
  envName: 'prod',
  autoClub: 'RAC',
  addressify: {
    baseUrl: 'https://api.addressify.com.au',
    apiKey: '0E4644FE-85E4-4685-930F-DBF5F8629DFA',
  },
  verisk: {
    username: 'BB3TokioMarineRACAusTravelProd',
    vrrInternal: 'eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiVG9raW8gTWFyaW5lIFJBQyBBdXMgVHJhdmVsIEJCMyIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6IkJsYWNrQm94Q3VzdG9tZXIiLCJjbGllbnRJZCI6IjEwIiwiaXNzIjoiQkIuTWFpbnRlbmFuY2VUb29sLlRlc3QiLCJhdWQiOiJWUlIuQkIzLlByb2QifQ.FZYiDO53k1fOe9gLVsoo_dgkcaU4_b8CFvPSPdyPP2k',
},
  baseAPIUrl:
    'https://apim-aue-ltx-prod-api-01.azure-api.net/v1/quote/api/',
  DMSAPI: {
    baseUrl: 'https://apim-aue-ltx-prod.azure-api.net/v1/',
    apiKey: '621809DEBVPN1198YMNEQU4W1XGTG',
    OcpApimSubscriptionKey: '1fc60dfc63504bbaaf5bdfdd642f37ad'
  },
  PASUrl: 'https://pas.tmaainsurance.com'
};