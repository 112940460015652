<div class="theme-static">
        <section class="header position-fixed w-100">
                <app-racv-common-header></app-racv-common-header>
        </section>

        <!-- End Header Part -->

        <!-- Start Page Header Title Part -->
        <section class="page-header-title">
                <div class="page-title-overlay"></div>
                <img src="assets/images/rac/page-header/travel-alerts-sub-page.png" alt="" class="w-100 page-title-image" />
                <div class="page-header-title-caption">
                        <h1>COVID-19</h1>
                </div>
        </section>
       

<main class="page-content py-5">

       <div class="container">
        <div class="page-content-info">
                <p><strong><span style="text-decoration: underline;">Updated travel insurance information regarding Coronavirus COVID-19 as at 1 July 2020<br>
                </span></strong></p>
                <p>Coronavirus COVID-19 is now deemed a known event which is a general exclusion under our policies.<br>
                <br>
                Our policy does not provide cover for any event you were aware of before your policy cover commenced.  If you purchased your policy on or after 1 July 2020, we would expect this was done with knowledge of Coronavirus COVID-19 and therefore there would be no coverage for any claim which was a result of this event. This applies to any section of the policy. <br>
                <br>
                Please refer to previously issued travel advices below for policies purchased prior to 1 July 2020. We will continue to monitor the situation and advise accordingly.</p>
                
                <p><span style="text-decoration: underline;"><strong><span>Updated travel information regarding Coronavirus as at 9 March 2020</span></strong></span></p>
                <p>This replaces previous travel advices and may be updated as developments occur.</p>
                <p> </p>
                <p><strong><span style="text-decoration: underline;">For customers whose policies were issued before 20 January 2020 at 7am (AEST) for travel to any country</span></strong></p>
                <p>No change to previous advices.</p>
                <p> </p>
                <p><strong><span style="text-decoration: underline;">For customers whose policies were issued after 20 January 2020 at 7am (AEST) for travel to  or through China</span></strong></p>
                <p><strong>Cancellation and all other sections of the policy (including Overseas Medical)</strong></p>
                <p><span>There is no change to our advice.  We re-confirm our policy does not provide coverage for known circumstances that would give rise to a claim.</span></p>
                <p><span>Therefore, any claim for cancellation/ rescheduling / deferring your trip as a result of the Coronavirus would be seen as a known circumstance that would give rise to a claim and your claim will not be coverable.</span></p>
                <p> </p>
                <p><strong><span style="text-decoration: underline;">For customers whose policies were issued after 20 January 2020 at 7am (AEST) to 31 January 2020 at 7am for travel to all other countries other than China</span></strong></p>
                <p><strong>Cancellation and other sections of the policy</strong></p>
                <p>Any claim for cancellation/<ins cite="mailto:Joaquim%20Smith" datetime="2020-03-09T11:57"> </ins>rescheduling / deferring your trip as a result of the Coronavirus may be claimable.</p>
                <p><span>Your claim will be assessed based on DFAT* travel advices in place at the time you purchased your policy and where you chose to travel.</span></p>
                <p><span>All other policy terms, conditions, limits and exclusions would apply to any claim lodged.</span></p>
                <p> </p>
                <p><strong><span>Overseas Medical claims in conjunction with associated additional expenses in relation to Coronavirus</span></strong></p>
                <p><span>If you incur overseas medical expenses with associated additional expenses as a result of contracting Coronavirus whilst on your trip, a claim for these expenses may be claimable. Your claim will be assessed based on policy terms and conditions and the DFAT* travel advice regarding “Do Not Travel” in place at the time you purchased your policy and where you chose to travel. </span></p>
                <p><span>All other policy terms, conditions, limits and exclusions would apply to any claim lodged.</span></p>
                <p><span> </span></p>
                <p><strong><span style="text-decoration: underline;">For customers whose polices were issued after 31 January 2020 after 7am (AEST) for travel to all OTHER countries</span></strong></p>
                 
                <p><strong>Cancellation and other sections of the policy</strong></p>
                <p>Our policy does not provide coverage for known circumstances that would give rise to a claim.</p>
                <p><span>Therefore, any claim for cancellation/ rescheduling / deferring your trip as a result of the Coronavirus would be seen as a known circumstance that would give rise to a claim and your claim will not be coverable.</span></p>
                <p><span> </span></p>
                <p><strong>Overseas Medical claims in conjunction with associated additional expenses in relation to Coronavirus</strong></p>
                <p><span>If you incur overseas medical expenses with associated additional expenses as a result of contracting Coronavirus whilst on your trip, a claim for these expenses may be claimable. Your claim will be assessed based on policy terms and conditions and the DFAT *travel advice regarding “Do Not Travel” in place at the time you purchased your policy and where you chose to travel. </span></p>
                <p><span>All other policy terms, conditions, limits and exclusions would apply to any claim lodged.</span></p>
                <p><span> </span></p>
                <p>*DFAT is the Department of Foreign Affairs and Trade. Please visit their website at <a href="http://www.smartraveller.gov.au/" target="_blank">www.smartraveller.gov.au/</a></p>
                <p><span style="text-decoration: none;"> </span></p>
                <p><strong><span style="text-decoration: underline;  ">Updated travel insurance information for Coronavirus as at 3rd March 2020</span></strong></p>
                <p><span>On 29th February 2020 the Australian government updated the travel advice for Iran to Do Not Travel. This means that if you purchase a policy to Iran whilst the Do Not Travel restriction is in place, there is no cover for any claims related to Coronavirus.<br>
                <br>
                All advice below still remain in force. <br>
                </span></p>
                
                <p><strong><span style="text-decoration: underline;  ">Updated travel insurance information for Coronavirus as at 14th February 2020</span></strong></p>
                <p><span>Please  note the following clarification in regards to possible coverage for reasonable additional expenses –<br>
                <br>
                If you have commenced your trip and your transport has been delayed/cancelled there may be coverage for reasonable additional expenses under Section 13 Travel Delay for travel, accommodation or meals. All policy limits, conditions and exclusions will apply to any claim lodged.</span></p>
                
                <p><strong><span style="text-decoration: underline;  ">Updated travel insurance information for Coronavirus as at 3rd February 2020</span></strong></p>
                <p><span>Over the weekend the Australian government updated the travel advice for China to Do Not Travel. This means that if you purchase a policy to China whilst the Do Not Travel restriction is in place, there is no cover for any claims related to Coronavirus.<br>
                <br> 
                The below advice still remains in force. <br>
                </span></p>
                
                <p><strong><span style="text-decoration: underline;  ">Travel insurance information for Coronavirus as at 28 January 2020</span></strong></p>
                <p><span>It has been widely reported through across the mainstream media that a virus called Coronavirus  has been detected in China and is now spreading to other parts of the world. Sadly there have been 81 reported deaths to date with the fear that number will continue to grow. The Australian government has also issued a Do Not Travel alert to the originating region of Wuhan, China.  <br>
                <br>
                All claims relating to the Coronavirus will be assessed based on your circumstances and in accordance with the Product Disclosure Statement (PDS) applicable to your policy level.</span></p>
                <p><span><br>
                <br>
                If your policy was purchased before 7am (AEST) on 20th January 2020 –<br>
                <br>
                - If you have commenced your trip and your transport has been delayed/cancelled there may be coverage for reasonable additional expenses for travel, accommodation or meals.  All policy limits, conditions and exclusions will apply to any claim lodged.<br>
                <br>
                - If you have commence your trip and you a hospitalised as a result of the threat or diagnosis of Coronavirus there may be coverage for any medical expenses incurred. Please ensure you contact our emergency assistance department to seek advice. <br>
                <br>
                - If you have not commenced your trip and your transport has been cancelled, delayed or rescheduled you may have coverage for cancellation or the rearrangement of your trip (if this is less than the cancellation cost).  You will need to contact your travel agent or transport provider to obtain any applicable refund, credit or confirmation of cancellation fees applicable.  Your travel provider may offer free options to rearrange your travel.</span></p>
                <p><span><br>
                <br>
                If you purchased your policy after 7am (AEST) on 20th January 2020 –<br>
                <br>
                Our policy does not provide cover for any event you were aware of before your policy cover commenced.  If you purchased your policy after 20th January 2020, we would expect this was done with knowledge of the current event and therefore there would be no coverage for any claim which was a result of this event.<br>
                <br>
                We will continue to monitor the situation and advise accordingly.<br>
                </span></p>
                	
        </div>
       </div>
      
</main>
        


        <app-racv-footer></app-racv-footer>
</div>