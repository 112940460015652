import { Injectable } from '@angular/core';
import { LocalCacheService } from './localCache.service';
import { environment } from '../../environments/environment';
import { Title } from '@angular/platform-browser';
declare global {
  interface Window {
    dataLayer: any[];
  }
}
@Injectable({
  providedIn: 'root',
})
export class GAService {
  constructor(
    private localCacheService: LocalCacheService,
    private titleService: Title,
  ) {}

  pageLoad() {
    this.safeExecute(() => {
      const pageInfo = {
        event: 'virtualPageView',
        page: {
          url: window.location.href,
          title: this.titleService.getTitle(),
        },
      };
      this.sendGAEvent(pageInfo);
    });
  }

  sendSelectItemDetail() {
    // step 1 select product cover
    const quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry');
    const quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    const selectedCoverDetail = this.localCacheService.getSessionStorage(
      'selectedCoverDetail',
    );
    this.safeExecute(() => {
      const eventDetails = {
        event: 'select_item',
        ecommerce: {
          currency: 'AUD',
          quote_id: quoteDetail.quoteNumber,
          items: [
            {
              item_id: quoteEnquiry.tripType,
              item_name: selectedCoverDetail.selectedCoverTitle,
              discount: quoteEnquiry.isClubMember
                ? selectedCoverDetail.selectedPrice.membershipDisc +
                  selectedCoverDetail.selectedPrice.promoCodeDisc
                : 0 + selectedCoverDetail.selectedPrice.promoCodeDisc,
              index:
                this.localCacheService.getSessionStorage('selectedTabIndex'),
              item_category: selectedCoverDetail.selectedPrice.excess,
              item_category2: selectedCoverDetail.selectedPrice.isCruise,
              item_category3: selectedCoverDetail.selectedPrice.isSki ?? false,
              item_category4:
                selectedCoverDetail.selectedPrice.multiTripLength ?? '',
              item_list_id: `${quoteEnquiry.tripType}_insurance`,
              item_list_name: `${quoteEnquiry.tripType}_insurance`,
              price: selectedCoverDetail.selectedPrice.regularPremium,
              quantity: 1,
            },
          ],
        },
      };
      this.sendGAEvent(eventDetails);
    });
  }

  sendViewItemEventDetail() {
    //step 1 after tab switch
    const quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry');
    const quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    const selectedCoverDetail = this.localCacheService.getSessionStorage(
      'selectedCoverDetail',
    );
    this.safeExecute(() => {
      const eventDetails = {
        event: 'view_item',
        ecommerce: {
          currency: 'AUD',
          quote_id: quoteDetail.quoteNumber,
          items: [
            {
              item_id: quoteEnquiry.tripType,
              item_name: selectedCoverDetail.selectedCoverTitle,
              discount: quoteEnquiry.isClubMember
                ? selectedCoverDetail.selectedPrice.membershipDisc +
                  selectedCoverDetail.selectedPrice.promoCodeDisc
                : 0 + selectedCoverDetail.selectedPrice.promoCodeDisc,
              index:
                this.localCacheService.getSessionStorage('selectedTabIndex'),
              item_category: selectedCoverDetail.selectedPrice.excess,
              item_category2: selectedCoverDetail.selectedPrice.isCruise,
              item_category3: selectedCoverDetail.selectedPrice.isSki ?? false,
              item_list_id: `${quoteEnquiry.tripType}_insurance`,
              item_list_name: `${quoteEnquiry.tripType}_insurance`,
              price: selectedCoverDetail.selectedPrice.regularPremium,
              quantity: '1',
            },
          ],
        },
      };
      this.sendGAEvent(eventDetails);
    });
  }

  sendAddToCartEventDetail() {
    // step 1 click proceed button
    const quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry');
    const quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    const selectedCoverDetail = this.localCacheService.getSessionStorage(
      'selectedCoverDetail',
    );
    this.safeExecute(() => {
      const eventDetails = {
        event: 'add_to_cart',
        ecommerce: {
          currency: 'AUD',
          value: selectedCoverDetail.selectedPrice?.totalPremium,
          quote_id: quoteDetail.quoteNumber,
          items: [
            {
              item_id: quoteEnquiry.tripType,
              item_name: selectedCoverDetail.selectedCoverTitle,
              discount: quoteEnquiry.isClubMember
                ? selectedCoverDetail.selectedPrice.membershipDisc +
                  selectedCoverDetail.selectedPrice.promoCodeDisc
                : 0 + selectedCoverDetail.selectedPrice.promoCodeDisc,
              index:
                this.localCacheService.getSessionStorage('selectedTabIndex'),
              item_category: selectedCoverDetail.selectedPrice.excess,
              item_category2: selectedCoverDetail.selectedPrice.isCruise,
              item_category3: selectedCoverDetail.selectedPrice.isSki ?? false,
              item_list_id: `${quoteEnquiry.tripType}_insurance`,
              item_list_name: `${quoteEnquiry.tripType}_insurance`,
              price: selectedCoverDetail.selectedPrice.regularPremium,
              quantity: 1,
            },
          ],
        },
      };
      this.sendGAEvent(eventDetails);
    });
  }

  sendViewCartEventDetail() {
    // view full price breakdown
    const quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry');
    const quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    const selectedCoverDetail = this.localCacheService.getSessionStorage(
      'selectedCoverDetail',
    );
    this.safeExecute(() => {
      const eventDetails = {
        event: 'view_cart',
        ecommerce: {
          currency: 'AUD',
          value: selectedCoverDetail.selectedPrice?.totalPremium,
          quote_id: quoteDetail.quoteNumber,
          items: [
            {
              item_id: quoteEnquiry.tripType,
              item_name: selectedCoverDetail.selectedCoverTitle,
              discount: quoteEnquiry.isClubMember
                ? selectedCoverDetail.selectedPrice.membershipDisc +
                  selectedCoverDetail.selectedPrice.promoCodeDisc
                : 0 + selectedCoverDetail.selectedPrice.promoCodeDisc,
              index:
                this.localCacheService.getSessionStorage('selectedTabIndex'),
              item_category: selectedCoverDetail.selectedPrice.excess,
              item_category2: selectedCoverDetail.selectedPrice.isCruise,
              item_category3: selectedCoverDetail.selectedPrice.isSki ?? false,
              item_list_id: `${quoteEnquiry.tripType}_insurance`,
              item_list_name: `${quoteEnquiry.tripType}_insurance`,
              price: selectedCoverDetail.selectedPrice.regularPremium,
              quantity: '1',
            },
          ],
        },
      };
      this.sendGAEvent(eventDetails);
    });
  }

  sendBeginCheckoutEventDetail() {
    // step 3 page load
    const quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry');
    const quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    const selectedCoverDetail = this.localCacheService.getSessionStorage(
      'selectedCoverDetail',
    );
    this.safeExecute(() => {
      const eventDetails = {
        event: 'begin_checkout',
        ecommerce: {
          currency: 'AUD',
          value: quoteDetail.premiums?.totalCost,
          quote_id: quoteDetail.quoteNumber,
          coupon: quoteDetail.promoCode,
          items: [
            {
              item_id: quoteEnquiry.tripType,
              item_name: selectedCoverDetail.selectedCoverTitle,
              discount: quoteEnquiry.isClubMember
                ? selectedCoverDetail.selectedPrice.membershipDisc +
                  selectedCoverDetail.selectedPrice.promoCodeDisc
                : 0 + selectedCoverDetail.selectedPrice.promoCodeDisc,
              index:
                this.localCacheService.getSessionStorage('selectedTabIndex'),
              item_category: selectedCoverDetail.selectedPrice.excess,
              item_category2: selectedCoverDetail.selectedPrice.isCruise,
              item_category3: selectedCoverDetail.selectedPrice.isSki ?? false,
              item_list_id: `${quoteEnquiry.tripType}_insurance`,
              item_list_name: `${quoteEnquiry.tripType}_insurance`,
              price: selectedCoverDetail.selectedPrice.regularPremium,
              quantity: '1',
            },
          ],
        },
      };
      this.sendGAEvent(eventDetails);
    });
  }

  sendAddPaymentInfoEventDetail(paymentMethod: any) {
    //step 4 click confirm and payment
    const quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry');
    const quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    const selectedCoverDetail = this.localCacheService.getSessionStorage(
      'selectedCoverDetail',
    );
    this.safeExecute(() => {
      const eventDetails = {
        event: 'add_payment_info',
        ecommerce: {
          currency: 'AUD',
          value: quoteDetail.premiums?.totalCost,
          quote_id: quoteDetail.quoteNumber,
          coupon: quoteDetail.promoCode,
          payment_type: paymentMethod,
          items: [
            {
              item_id: quoteEnquiry.tripType,
              item_name: selectedCoverDetail.selectedCoverTitle,
              discount: quoteEnquiry.isClubMember
                ? selectedCoverDetail.selectedPrice.membershipDisc +
                  selectedCoverDetail.selectedPrice.promoCodeDisc
                : 0 + selectedCoverDetail.selectedPrice.promoCodeDisc,
              index:
                this.localCacheService.getSessionStorage('selectedTabIndex'),
              item_category: selectedCoverDetail.selectedPrice.excess,
              item_category2: selectedCoverDetail.selectedPrice.isCruise,
              item_category3: selectedCoverDetail.selectedPrice.isSki ?? false,
              item_list_id: `${quoteEnquiry.tripType}_insurance`,
              item_list_name: `${quoteEnquiry.tripType}_insurance`,
              price: selectedCoverDetail.selectedPrice.regularPremium,
              quantity: 1,
            },
          ],
        },
      };
      this.sendGAEvent(eventDetails);
    });
  }

  sendPurchaseEventDetail(movementGuid: string) {
    //step 5 after page load
    const quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry');
    const quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    const selectedCoverDetail = this.localCacheService.getSessionStorage(
      'selectedCoverDetail',
    );
    this.safeExecute(() => {
      const eventDetails = {
        event: 'purchase',
        ecommerce: {
          currency: 'AUD',
          value: quoteDetail.premiums?.totalCost,
          tax: quoteDetail.premiums?.gst,
          coupon: quoteDetail.promoCode,
          quote_id: quoteDetail.quoteNumber,
          transaction_id: movementGuid,
          items: [
            {
              item_id: quoteEnquiry.tripType,
              item_name: selectedCoverDetail.selectedCoverTitle,
              discount: quoteEnquiry.isClubMember
                ? selectedCoverDetail.selectedPrice.membershipDisc +
                  selectedCoverDetail.selectedPrice.promoCodeDisc
                : 0 + selectedCoverDetail.selectedPrice.promoCodeDisc,
              index:
                this.localCacheService.getSessionStorage('selectedTabIndex'),
              item_category: selectedCoverDetail.selectedPrice.excess,
              item_category2: selectedCoverDetail.selectedPrice.isCruise,
              item_category3: selectedCoverDetail.selectedPrice.isSki ?? false,
              item_category4:
                selectedCoverDetail.selectedPrice.multiTripLength ?? '',
              item_list_id: `${quoteEnquiry.tripType}_insurance`,
              item_list_name: `${quoteEnquiry.tripType}_insurance`,
              price: selectedCoverDetail.selectedPrice.regularPremium,
              quantity: 1,
            },
          ],
        },
      };
      this.sendGAEvent(eventDetails);
    });
  }

  sendQuoteEmailEventDetail(email: string) {
    const quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    const selectedCoverDetail = this.localCacheService.getSessionStorage(
      'selectedCoverDetail',
    );
    this.safeExecute(() => {
      const eventDetails = {
        event: 'quote_email',
        currency: 'AUD',
        value: selectedCoverDetail.selectedPrice.totalPremium,
        quote_id: quoteDetail.quoteNumber,
        user_email: email,
      };
      window.dataLayer.push(eventDetails);
    });
  }

  sendUserEmailEventDetail() {
    const quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    this.safeExecute(() => {
      const eventDetails = {
        event: 'user_email',
        user_email: quoteDetail.travelers[0]?.email,
      };
      window.dataLayer.push(eventDetails);
    });
  }

  sendGAEvent(eventDetails: any) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push(eventDetails);
    console.log('GA Event: window.dataLayer new', window.dataLayer);
  }

  safeExecute(fn: any) {
    try {
      fn();
    } catch (error) {
      console.error('An error occurred in the Google analytics code:', error);
    }
  }
}
