import { Component } from '@angular/core';

@Component({
  selector: 'app-rac-policy-benefits',
  standalone: true,
  imports: [],
  templateUrl: './rac-policy-benefits.component.html',
  styleUrl: './rac-policy-benefits.component.scss',
})
export class RACPolicyBenefitsComponent {}
