<section>
  <div class="container">
    <div class="row py-5 align-items-center">
      <div class="col-lg-5 text-center text-md-start">
        <h2 class="fw-bold font_open text-center" style="font-size: 28px;">Policy Benefits</h2>
        <div class="text-center">
          <img src="assets/images/rac/policy.png" alt="" style="max-width: 150px;">
        </div>
      </div>
      <div class="col-lg-7">
        <div>
          <ul class="check-circle font_roboto">
            <li><strong>Members save 15%</strong> Not a member? <a target="_blank" href="https://rac.com.au/membership-benefits/become-a-member/rewards-membership?_ga=2.210236828.885286628.1580904837-128620797.1580312459">Sign up today</a> for $29 and start saving</li>					
            <li><strong>$Unlimited medical</strong> You have an $unlimited medical benefit when travelling overseas</li>
            <li><strong>Dependent coverage</strong> Your children and Grandchildren 25 or under are covered when they are travelling with you</li>
            <li><strong>24/7 assistance</strong> Our worldwide assistance team is available 24 hours a day</li>
            <li><strong>Winter sport and ski cover</strong> We offer great ski cover, check us out</li>
            <li><strong>New for old luggage replacement</strong></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>