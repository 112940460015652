<div class="theme-static">
        <section class="header position-fixed w-100">
                <app-racv-common-header></app-racv-common-header>
        </section>

        <!-- End Header Part -->

        <!-- Start Page Header Title Part -->
        <section class="page-header-title">
                <div class="page-title-overlay"></div>
                <img src="assets/images/rac/page-header/travel-alerts-sub-page.png" alt="" class="w-100 page-title-image" />
                <div class="page-header-title-caption">
                        <h1>Do Not Travel</h1>
                </div>
        </section>
      
        <main class="page-content py-5">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-12">
            
                      <div class="page-content-info">
                        <p ><span >This information is in relation to the ‘Do Not Travel’ Warning currently in effect for all travel outside of Australia.</span></p>
            
                        <p><span >Due to the risk and spread of COVID-19 Coronavirus, Scott Morrison has banned indoor gatherings of more than 100 people and issued a blanket 'Do Not Travel' advice for all Australians for the first time in history. Information placed on smartraveller <a  href="https://www.smartraveller.gov.au/crisis/covid-19-and-travel">https://www.smartraveller.gov.au/crisis/covid-19-and-travel</a> states (in part):</span></p>
            
                        <p><span >"We now advise all Australians: do not travel overseas at this time. This is our highest advice level (level 4 of 4). Regardless of your destination, age or health, our advice is do not travel at this time."</span></p>
            
                        <p><span >For all policies purchased after 9am (AEST) on 18th March 2020 –</span></p>
            
                        <p><span >Our policy does not provide cover for any event you were aware of before your policy cover commenced. If you purchased your policy after 9am 18 March 2020 we would expect this was done with knowledge of the current Do Not Travel notification in place and therefore there would be no coverage for any claim which was a result of this event. *However, if the Do Not Travel notification is lifted prior to your trip departure date, then the standard policy terms, limits, conditions and exclusions relevant to the current Product Disclosure Statement apply.</span></p>
            
                        <p><span ><strong>*text inserted with effect 9am (AEST) Tuesday 6<sup>th</sup> April 2021.</strong></span></p>
            
                        <p><span >Please refer to the documents titled - Updated Travel insurance information for Coronavirus, Travel insurance information for China and Iran – Do Not Travel, and COVID-19 Coronavirus - Frequently asked Questions, for more information specifically relating to policies purchased and trips booked prior to today.</span></p>	
                      </div>
            
                     
             
                    </div>
            
                    
                  </div>
                </div>
              </main>
   
              
        <app-racv-footer></app-racv-footer>
</div>