export const questionnairesList = [
  {
    RAC: [
      {
        id: 1,
        question:
          'Are you/this person travelling against medical advice or for medical treatment?',
      },
      {
        id: 2,
        question:
          'Are you/this person currently awaiting surgery, treatment, investigation or procedures?',
      },
      {
        id: 3,
        question:
          'Have you/this person ever had or currently have any condition that is chronic, ongoing, terminal, or has affected or involves your/their heart, circulatory system, lungs or respiratory system, brain, kidneys, liver, or any type of cancer, or ever had a surgery involving the back, neck, joints or abdomen, which is reasonably known to you, for which you wish to declare and apply for cover?',
      },
      {
        id: 4,
        question:
          'Do you/this person understand that any medical condition not declared may result in cover being denied under this Policy, in whole or in part, to the extent that this condition has contributed toward your loss?',
      }
    ]
  }
];
