import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { MessageService } from 'primeng/api';
import { LocalCacheService } from './localCache.service';
import { environment } from '../../environments/environment';
import { Utility } from '../utils/utils';
@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  private loadingMaskFeatures: string[] = [];
  private maskVisibleSubject = new BehaviorSubject<boolean>(false);
  showLoadingMask$ = this.maskVisibleSubject.asObservable();

  private emailUrl = `${environment.baseAPIUrl}quote/document/email`;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private localCacheService: LocalCacheService,
  ) {}

  showLoading(featureName: string) {
    setTimeout(() => {
      this.loadingMaskFeatures.push(featureName);
      console.log('showLoading', this.loadingMaskFeatures);
      if (this.loadingMaskFeatures.length > 0) {
        this.maskVisibleSubject.next(true);
      }
    }, 300); // fixing NG0100: ExpressionChangedAfterItHasBeenCheckedError issue
  }

  hideLoading(featureName: string) {
    setTimeout(() => {
      const newLoadingList = this.loadingMaskFeatures.filter(
        (feature) => feature !== featureName,
      );
      this.loadingMaskFeatures = newLoadingList;
      console.log('hideLoading', this.loadingMaskFeatures);
      if (this.loadingMaskFeatures.length === 0) {
        this.maskVisibleSubject.next(false);
      }
    }, 310); // fixing NG0100: ExpressionChangedAfterItHasBeenCheckedError issue
  }

  clearLoading() {
    setTimeout(() => {
      this.loadingMaskFeatures = [];
      this.maskVisibleSubject.next(false);
    }, 310); // fixing NG0100: ExpressionChangedAfterItHasBeenCheckedError issue
  }

  sendEmail(emailId: any): Observable<any> {
    this.showLoading('sendEmail');
    const emailRequest = {
      quoteId:
        this.localCacheService.getSessionStorage('quoteDetail')?.quoteId ?? '',
      email: emailId,
    };
    return this.http
      .post<any>(this.emailUrl, emailRequest, this.httpOptions)
      .pipe(
        tap((_) => {
          this.hideLoading('sendEmail');
          console.log('Email sent');
        }),
        catchError(this.handleError<any>('sendEmail')),
      );
  }

  getCurrentAssessmentDetails(): any {
    const assessmentDetails =
      this.localCacheService.getSessionStorage('assessmentDetails');
    const currentAssessment =
      this.localCacheService.getSessionStorage('currentAssessment');
    if (currentAssessment === 'primary' || currentAssessment === 'secondary') {
      return assessmentDetails[currentAssessment];
    } else if (currentAssessment === 'dependent') {
      const travelerDetails =
        this.localCacheService.getSessionStorage('travelerDetails');
      const dependentIndex =
        this.localCacheService.getSessionStorage('dependentIndex');
      const travelerId =
        travelerDetails?.dependents[dependentIndex]?.travelerId;
      if (travelerId) {
        return assessmentDetails[currentAssessment].find(
          (detail: any) => detail.travelerId === travelerId,
        );
      } else {
        return {};
      }
    }
    return {};
  }

  getCurrentAssessmentName(): string {
    const currentAssessment =
      this.localCacheService.getSessionStorage('currentAssessment');
    if (currentAssessment === 'primary') {
      return (
        this.localCacheService.getSessionStorage('travelerDetails').adult1
          .firstName +
        ' ' +
        this.localCacheService.getSessionStorage('travelerDetails').adult1
          .lastName
      );
    } else if (currentAssessment === 'secondary') {
      return (
        this.localCacheService.getSessionStorage('travelerDetails').adult2
          .firstName +
        ' ' +
        this.localCacheService.getSessionStorage('travelerDetails').adult2
          .lastName
      );
    } else if (currentAssessment === 'dependent') {
      const dependentIndex =
        this.localCacheService.getSessionStorage('dependentIndex');
      return (
        this.localCacheService.getSessionStorage('travelerDetails').dependents[
          dependentIndex
        ].firstName +
        ' ' +
        this.localCacheService.getSessionStorage('travelerDetails').dependents[
          dependentIndex
        ].lastName
      );
    }
    return '';
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  public handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      this.clearLoading();

      throwError(
        () => new Error(`Custom error message. Status code: ${error.status}`),
      );
      // Let the app keep running by returning an empty result.
      return of({
        error: true,
        errorMessage: `An error occurred with status code: ${error.status}`,
      } as T);
    };
  }

  /** Log a CountryService message with the MessageService */
  private log(message: string) {
    console.log(`api calling error: ${message} `);
    this.messageService.add({
      severity: 'error',
      summary: '',
      detail: 'Something went wrong. Please try again later.',
    });
  }

  getQueryParamInfo(paramName: string): string {
    let param: any;
    switch (paramName) {
      case 'emailquoteid':
        {
          param =
            Utility.getUrlParameter('emailquoteid') ||
            Utility.getUrlParameter('q'); // This can be remove once api modefy its url param from emailquoteid to q
          param = Utility.IsValidGuid(param) ? param : '';
        }
        break;
      case 'QuoteGuid':
        {
          param = Utility.getUrlParameter('q');
          param = atob(param);
          param = Utility.IsValidGuid(param) ? param : '';
        }
        break;
      case 'AgentGuid':
        {
          param = Utility.getUrlParameter('a');
          param = atob(param);
          param = Utility.IsValidGuid(param) ? param : '';
        }
        break;
      case 'BranchGuid':
        {
          param = Utility.getUrlParameter('b');
          param = atob(param);
          param = Utility.IsValidGuid(param) ? param : '';
        }
        break;
      case 'AgentName':
        {
          param = Utility.getUrlParameter('n') || '';
          param = atob(param);
        }
        break;
      case 'BranchName':
        {
          param = Utility.getUrlParameter('b') || '';
          param = atob(param);
        }
        break;
      case 'CTMToken':
        {
          param = Utility.getUrlParameter('t') || '';
          param = atob(param);
        }
        break;
      case 'FinderToken':
        {
          param = Utility.getUrlParameter('vf') || '';
          param = atob(param);
        }
        break;
      case 'Role':
        {
          param = Utility.getUrlParameter('e') || '';
          param = atob(param);
        }
        break;
      case 'AgentEmail':
        {
          param = Utility.getUrlParameter('g') || '';
          param = atob(param);
        }
        break;
      default:
        param = '';
        break;
    }
    return param;
  }

  checkAndValidatePage(
    validatedPageCount: any,
    afterSubmitAction: boolean,
  ): boolean {
    const _lastPageCount = sessionStorage.getItem('validatedPageCount') || -1;

    // If the caller is on submit after validation then set the state of validated page number
    if (afterSubmitAction) {
      sessionStorage.setItem('validatedPageCount', validatedPageCount);
    }
    // Validate previous page
    if (
      Number(_lastPageCount) >= validatedPageCount - 1 ||
      validatedPageCount == 0
    ) {
      return true;
    }

    return false;
  }

  getUrlParamsToJson() {
    const urlParams = new URLSearchParams(window.location.search);
    if (!urlParams.toString()) {
      // No parameters found in the URL
      return null;
    }
    //const paramsArray = [] as any[];
    const paramsObject = {} as any;

    // Iterate through all parameters and add them to both the array and object
    urlParams.forEach((value, key) => {
      let decodedValue = value;
      if (
        key === 'q' ||
        key === 'a' ||
        key === 'b' ||
        key === 'n' ||
        key === 't' ||
        key === 'vf' ||
        key === 'e'
      ) {
        decodedValue = this.decodeIfEncrypted(value); // Decode if encrypted
      }
      //paramsArray.push({ key, value: decodedValue });
      paramsObject[key] = decodedValue;
    });

    return paramsObject;
  }

  decodeIfEncrypted(value: string) {
    try {
      // Try to decode using atob, if it's not Base64, it'll throw an error
      const decoded = atob(value);

      // Optional check: Re-encode and see if it matches the original, ensuring it's Base64
      //return btoa(decoded) === value ? decoded : value;
      return decoded;
    } catch (e) {
      // If decoding fails, it's not encoded in Base64, so return the original
      return value;
    }
  }

  showToastPopup(message: string) {
    console.log(`api calling error: ${message} `);
    this.messageService.add({
      severity: 'error',
      summary: '',
      detail: message,
    });
  }
}
